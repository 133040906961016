import { createContext, useContext, useState } from "react";
import arenaService from "../../../../api/ArenaService";
import { Arena } from "../../../../common/models/Models";

type ArenaState = {
  config: Arena;
  setConfig: (config: Arena) => void;
  saveArena: (config: any) => Promise<Arena>;
};

export const useArena = (): ArenaState => {
  const arenaState = useContext(ArenaContext);
  if (!arenaState) {
    throw new Error("useArena must be used within a ArenaProvider");
  }
  return arenaState;
};

const ArenaContext = createContext<ArenaState | null>(null);

type ArenaProviderProps = {
  children: JSX.Element;
  preFetchedValues: [Arena];
};

const ArenaProvider = ({ children, preFetchedValues }: ArenaProviderProps) => {
  const [config, setConfig] = useState<Arena>(preFetchedValues[0]);

  const saveArena = async (arenaParams: any) => {
    const a = await arenaService.updateArena(
      config.createdTimestamp,
      arenaParams
    );
    setConfig(a);
    return a;
  };

  return (
    <ArenaContext.Provider
      value={{
        config,
        setConfig,
        saveArena,
      }}
    >
      {children}
    </ArenaContext.Provider>
  );
};

export default ArenaProvider;
