import { Button, Stack, Typography } from "@mui/material";
import {
  HeadingText,
  SmallText,
} from "../../../../../common/styles/Text.styles";
import {
  borderGrey,
  defaultGap,
  iconSize,
  perelynGrey,
  tinyGap,
} from "../../../../../styles/consts";
import UserPrompt from "./UserPrompt";

export default function TopMarketingConfig() {
  return (
    <Stack
      sx={{
        width: "100%",
        flexDirection: "row",
        gap: "3rem",
        paddingBottom: defaultGap,
        boxSizing: "border-box",
      }}
    >
      <Stack
        sx={{
          gap: iconSize,
        }}
      >
        <HeadingText>Let's get started</HeadingText>
        <HeadingText>Add text*</HeadingText>
        <SmallText>
          Please add an URL to a website, a PDF or text as source for the social
          media materials.
        </SmallText>

        <Typography
          sx={{
            fontSize: "0.625rem",
            color: perelynGrey,
          }}
        >
          Don't have a text yet? Try our Writing assistant tool.
        </Typography>
        <Button
          sx={{
            justifyContent: "center",
            width: "fit-content",
            padding: `0 ${tinyGap}`,
            color: perelynGrey,
            fontSize: "0.625rem",
            border: borderGrey,
          }}
        >
          Try now
        </Button>
      </Stack>
      <UserPrompt />
    </Stack>
  );
}
