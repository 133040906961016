import { CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import agentOneService from "../../../../api/AgentOneService";
import { usePollArena } from "../../../../common/hooks/usePollArena";
import { FullStack } from "../../../../common/styles/Box.styles";
import { PrimaryButton } from "../../../../common/styles/Button.styles";
import { HeadingText } from "../../../../common/styles/Text.styles";
import {
  borderStyle,
  defaultGap,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../../styles/consts";
import ConfigSelect from "../components/config/ConfigSelect";
import ArenaConfigBox from "./ArenaConfigBox";
import ArenaOutputSection from "./ArenaOutputSection";
import { useArena } from "./ArenaProvider";
import ArenaUserPrompt from "./ArenaUserPrompt";

export default function Arena() {
  const { config } = useArena();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  usePollArena(
    isGenerating &&
      (!config.processStatuses?.marketingTextA?.result ||
        !config.processStatuses?.marketingTextB?.result)
  );

  useEffect(() => {
    if (
      config.processStatuses?.marketingTextA?.result &&
      config.processStatuses?.marketingTextB?.result
    ) {
      setIsGenerating(false);
    }
  }, [config.processStatuses]);

  const handleGenerate = async () => {
    if (
      config.userPrompts.marketingTextA?.use &&
      config.userPrompts.marketingTextA?.content &&
      config.userPrompts.marketingTextA?.name &&
      config.userPrompts.marketingTextB?.use &&
      config.userPrompts.marketingTextB?.content &&
      config.userPrompts.marketingTextB?.name
    ) {
      config.configs.marketingTextA.nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1 =
        config.userPrompts.marketingTextA.content +
        " The post should be between" +
        config.configs.marketingTextA.nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1.split(
          "The post should be between"
        )[1];
      config.configs.marketingTextB.nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1 =
        config.userPrompts.marketingTextB.content +
        " The post should be between" +
        config.configs.marketingTextB.nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1.split(
          "The post should be between"
        )[1];
      config.configs.marketingTextA.nodes.WriteLinkedinPostNode.promptPresets.value.presetName =
        config.userPrompts.marketingTextA.name;
      config.configs.marketingTextB.nodes.WriteLinkedinPostNode.promptPresets.value.presetName =
        config.userPrompts.marketingTextB.name;
    }
    setIsGenerating(true);
    await agentOneService.runMarketingTextArena(config);
    setIsDisabled(true);
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      return "";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <FullStack>
      <Stack
        sx={{
          padding: defaultGap,
          gap: smallerGap,
        }}
      >
        <Stack
          sx={{
            border: borderStyle,
          }}
        >
          <Stack
            sx={{
              width: "100%",
              padding: smallerGap,
              flexDirection: "row",
              justifyContent: "space-between",
              boxSizing: "border-box",
              gap: "3rem",
            }}
          >
            <HeadingText>Customize your prompt</HeadingText>
            <ConfigSelect config={config.configs.marketingTextA} />
          </Stack>
          <Stack
            sx={{
              padding: smallerGap,
              flexDirection: "row",
              gap: defaultGap,
            }}
          >
            <ArenaConfigBox isDisabled={isDisabled} />
            <ArenaUserPrompt isDisabled={isDisabled} />
          </Stack>
          <Stack
            sx={{
              padding: smallGap,
              flexDirection: "row",
              justifyContent: "right",
              gap: tinyGap,
            }}
          >
            <PrimaryButton
              id="GenerateResults"
              disabled={
                isDisabled ||
                !!config.processStatuses?.marketingTextA?.result ||
                !!config.processStatuses?.marketingTextB?.result
              }
              onClick={handleGenerate}
            >
              Generate results
            </PrimaryButton>
          </Stack>
        </Stack>
        <Stack sx={{ border: borderStyle }}>
          <Stack
            sx={{
              padding: smallGap,
              gap: smallerGap,
            }}
          >
            <Stack
              sx={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Typography sx={{ fontSize: "1.5rem" }}>
                Compare two results below and vote for the better one
              </Typography>
              {isGenerating && (
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: smallGap,
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={defaultGap} />
                </Stack>
              )}
            </Stack>

            <ArenaOutputSection />
          </Stack>
        </Stack>
      </Stack>
    </FullStack>
  );
}
