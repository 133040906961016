import { createContext, ReactNode, useContext, useState } from "react";
import { Task } from "../../../common/models/Models";

type TasksDashboardState = {
  tasks: Task[];
  setTasks: (tasks: Task[]) => void;
};

const TasksDashboardContext = createContext<TasksDashboardState | undefined>(
  undefined
);

export const useTasksDashboard = (): TasksDashboardState => {
  const tasksDashboardState = useContext(TasksDashboardContext);
  if (!tasksDashboardState) {
    throw new Error("useDashboardTasks must be used within a UserProvider");
  }
  return tasksDashboardState;
};

export default function TasksDashboardProvider({
  children,
  preFetchedValues,
}: {
  children: ReactNode;
  preFetchedValues: any[][];
}) {
  const [tasks, setTasks] = useState<Task[]>(preFetchedValues[0] as Task[]);

  const tasksDashboardState: TasksDashboardState = {
    tasks,
    setTasks,
  };

  return (
    <TasksDashboardContext.Provider value={tasksDashboardState}>
      {children}
    </TasksDashboardContext.Provider>
  );
}
