import AutoUpdateTextField from "../../../../common/components/fundamentals/AutoUpdateTextField";
import { useArena } from "./ArenaProvider";

export default function ArenaAutoUpdateTextField({
  defaultValue,
  propPath,
  ...props
}: {
  defaultValue: string;
  propPath: string | string[];
  [key: string]: any;
}) {
  const { saveArena } = useArena();
  return (
    <AutoUpdateTextField
      defaultValue={defaultValue}
      propPath={propPath}
      saveFunction={saveArena}
      {...props}
    />
  );
}
