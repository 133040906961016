import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { BiggerIcon } from "../../../../../common/components/fundamentals/Icon";
import {
  FullStack,
  GreyOnHoverStack,
} from "../../../../../common/styles/Box.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import { borderStyle, checkboxGap } from "../../../../../styles/consts";
import { flip } from "../../../../../utils";

const popUpBackgroundClass = "popUpBackground";

export default function PopUpBox({
  button,
  heading,
  children,
}: {
  button: any;
  heading?: string;
  children: any;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const renderChildren =
    typeof children === "function" ? children(setIsOpen) : children;

  return (
    <Stack>
      <Box sx={{ width: "fit-content" }} onClick={flip(setIsOpen)}>
        {button}
      </Box>
      {isOpen && (
        <FullStack
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "9999",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            justifyContent: "center",
            alignItems: "center",
            cursor: "crosshair",
          }}
          className={popUpBackgroundClass}
          onClick={(e: any) => {
            if (e.target.classList.contains(popUpBackgroundClass)) {
              flip(setIsOpen)();
            }
          }}
        >
          <Stack
            sx={{
              width: "40%",
              minHeight: "30%",
              backgroundColor: "white",
              zIndex: "99999",
              cursor: "default",
            }}
          >
            <Stack
              sx={{
                paddingLeft: checkboxGap,
                border: borderStyle,
                borderBottom: "none",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <HeadingText
                sx={{
                  cursor: "text",
                }}
              >
                {heading}
              </HeadingText>

              <GreyOnHoverStack>
                <BiggerIcon
                  sx={{
                    height: "3rem",
                    width: "3rem",
                  }}
                  name="BigXMark"
                  onClick={() => setIsOpen(false)}
                />
              </GreyOnHoverStack>
            </Stack>

            {renderChildren}
          </Stack>
        </FullStack>
      )}
    </Stack>
  );
}
