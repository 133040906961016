import { Stack } from "@mui/material";

import Choice from "../../../../../../../common/components/fundamentals/Choice";
import { MediumText } from "../../../../../../../common/styles/Text.styles";
import { smallerGap } from "../../../../../../../styles/consts";
import { useTask } from "../../../../context/TaskProvider";
import ConfigBox from "../../ConfigBox";
import DropdownWithLabel from "./DropdownWithLabel";
import SocialMediaChoice from "./SocialMediaChoice";

export default function SocialMediaSettings() {
  const { task, saveTask, selectedParam } = useTask();

  return (
    <ConfigBox>
      <Stack>
        <MediumText>Social media settings</MediumText>
      </Stack>
      <Stack>
        <Stack sx={{ flexDirection: "row", gap: "2.75rem" }}>
          {task.configs.marketingText.general.contentType.options.map(
            (socialMedia, idx) => (
              <SocialMediaChoice
                isCurrentlySelected={selectedParam === socialMedia}
                name={socialMedia}
                key={idx}
              />
            )
          )}
        </Stack>
      </Stack>
      <Stack>
        {task.configs.marketingText.general.languages.options.map(
          (language, idx) => (
            <Choice
              choice={language}
              key={idx}
              isLast={
                idx ===
                task.configs.marketingText.general.languages.options.length - 1
              }
              valueList={task.configs.marketingText.general.languages.value}
              checkIsCurrentlySelected={(choice, t) =>
                t.configs.marketingText.general.languages.value.includes(choice)
              }
              valueParamPath="configs.marketingText.general.languages.value"
            />
          )
        )}
      </Stack>
      <Stack gap={smallerGap}>
        <DropdownWithLabel
          label="Target group"
          options={
            task.configs.marketingText.nodes.WriteLinkedinPostNode.targetGroup
              .options
          }
          value={
            task.configs.marketingText.nodes.WriteLinkedinPostNode.targetGroup
              .value
          }
          onChange={(value) => {
            saveTask({
              "configs.marketingText.nodes.WriteLinkedinPostNode.targetGroup.value":
                value,
            });
          }}
        />
      </Stack>
    </ConfigBox>
  );
}
