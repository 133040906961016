import { useLayoutConfig } from "../../../common/context/LayoutConfigProvider";
import { Task } from "../../../common/models/Models";
import { TaskStatusIcon } from "../../../common/styles/Form.styles";
import { SmallText } from "../../../common/styles/Text.styles";
import { perelynError, perelynSuccess } from "../../../styles/consts";
import { capitalizeFirstLetter } from "../../../utils";

const colorLookup = {
  green: {
    background: "#B0F1D2",
    text: perelynSuccess,
  },
  red: {
    background: "#FFB0AB",
    text: perelynError,
  },
};

const mapping = {
  "marketing-text": "marketingText",
  "pdf-data-extraction": "entityExtraction",
};

export default function TaskStatus({ task }: { task: Task }) {
  const { moduleName } = useLayoutConfig();
  const statusProp = mapping[moduleName] || moduleName;
  const ts =
    task.processStatuses && task.processStatuses[statusProp].status
      ? {
          label: capitalizeFirstLetter(
            task.processStatuses[statusProp].status
              .toLowerCase()
              .replaceAll("_", " ")
          ),
          color: "green",
        }
      : { label: "Unfinished", color: "red" };
  return (
    <TaskStatusIcon
      sx={{
        backgroundColor: colorLookup[ts.color].background,
        opacity: 0.9,
      }}
    >
      <SmallText
        sx={{
          whiteSpace: "nowrap",
          color: colorLookup[ts.color].text,
        }}
      >
        {ts.label}
      </SmallText>
    </TaskStatusIcon>
  );
}
