import { Typography } from "@mui/material";
import { FullStack } from "../../../styles/Box.styles";
import "./style.css";

export default function Loader({ label }: { label?: string }) {
  return (
    <FullStack
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loader" />
      {label && (
        <Typography
          sx={{
            marginTop: "10rem",
          }}
        >
          {label}
        </Typography>
      )}
    </FullStack>
  );
}
