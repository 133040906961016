import { useEffect, useState } from "react";

export default function useData<T>(fetchFunction: () => Promise<T>): {
  data: T | null;
} {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const user = await fetchFunction();
      setData(user);
    };

    fetchData();
  }, [fetchFunction]);

  return {
    data,
  };
}
