import { Stack } from "@mui/material";
import { useState } from "react";
import Dropdown from "../../../../../common/components/fundamentals/Dropdown";
import TaskAutoUpdateTextField from "../../../../../common/components/fundamentals/TaskAutoUpdateTextField";
import { defaultGap, smallGap } from "../../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../../utils";
import UploadBox from "../../../PdfDataExtractionTool/components/config/UploadBox";
import { useTask } from "../../context/TaskProvider";
import ContextCustomization from "./section/ContextCustomization";

export default function UserPrompt() {
  const { task, saveTask } = useTask();
  const [inputSourceType, setInputSourceType] = useState(
    task.configs.marketingText.general.inputSource.type
  );

  const textFieldProps = {
    user_prompt: {
      placeholder: "Enter user prompt*",
      multiline: true,
      rows: 10,
    },
    website: {
      placeholder: "Enter website URL*",
    },
  };
  return (
    <Stack
      sx={{
        width: "100%",
        gap: smallGap,
      }}
    >
      <Stack
        sx={{
          width: "100%",
          gap: defaultGap,
        }}
      >
        <Dropdown
          initialSelected={inputSourceType}
          options={task.configs.marketingText.general.inputSource.type_options.map(
            (o) => {
              return {
                label: capitalizeFirstLetter(replaceUnderscoreForWhitespace(o)),
                value: o,
              };
            }
          )}
          onChange={async (value) => {
            setInputSourceType(value);
            const t = await saveTask({
              "configs.marketingText.general.inputSource.type": value,
              "configs.marketingMetadata.general.inputSource.type": value,
            });
            setInputSourceType(
              t.configs.marketingText.general.inputSource.type
            );
          }}
        />
        {inputSourceType !== "s3" ? (
          <TaskAutoUpdateTextField
            id="userPrompt"
            defaultValue={
              task.configs.marketingText.general.inputSource.value === "input"
                ? ""
                : task.configs.marketingText.general.inputSource.value
            }
            propPath={[
              "configs.marketingText.general.inputSource.value",
              "configs.marketingMetadata.general.inputSource.value",
            ]}
            sx={{
              width: "100%",
            }}
            {...textFieldProps[inputSourceType]}
          />
        ) : (
          <UploadBox
            value={task.configs.marketingText.general.inputSource.value}
            propertyPath="configs.marketingText.general.inputSource.value"
            module="marketingText"
          />
        )}
      </Stack>
      <Stack
        sx={{
          justifyContent: "space-between",
          gap: smallGap,
        }}
      >
        <TaskAutoUpdateTextField
          id="comment"
          placeholder="Add a comment*"
          defaultValue={
            task.configs.marketingText.nodes.WriteLinkedinPostNode.comments
              .value === null
              ? ""
              : task.configs.marketingText.nodes.WriteLinkedinPostNode.comments
                  .value
          }
          propPath={
            "configs.marketingText.nodes.WriteLinkedinPostNode.comments.value"
          }
          multiline
          maxRows="3"
          sx={{
            width: "100%",
          }}
        />
        <ContextCustomization />
      </Stack>
    </Stack>
  );
}
