import { Stack } from "@mui/material";
import Icon from "../../common/components/fundamentals/Icon";
import Image from "../../common/components/fundamentals/Image";
import { OrangeButton } from "../../common/styles/Button.styles";
import {
  borderStyle,
  defaultGap,
  perelynLightGrey,
  smallerGap,
} from "../../styles/consts";

export default function ImageBoxOverview() {
  return (
    <Stack
      sx={{
        justifyContent: "space-between",
        border: borderStyle,
        minWidth: "50%",
        backgroundColor: perelynLightGrey,
      }}
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Image
          sx={{ height: "18rem" }}
          type="img"
          src="MarketingTextImage.png"
        />
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          borderTop: borderStyle,
          justifyContent: "space-between",
        }}
      >
        <OrangeButton
          sx={{
            borderRight: borderStyle,
            "&&": {
              minWidth: defaultGap,
              width: defaultGap,
            },
            ":hover": {
              "&&": {
                backgroundColor: perelynLightGrey,
              },
            },
          }}
        >
          <Icon
            sx={{
              height: smallerGap,
              width: smallerGap,
              transform: "scaleX(-1)",
            }}
            name="ChevronRight"
          />
        </OrangeButton>
        <OrangeButton
          sx={{
            borderLeft: borderStyle,
            "&&": {
              minWidth: defaultGap,
              width: defaultGap,
            },
            ":hover": {
              "&&": {
                backgroundColor: perelynLightGrey,
              },
            },
          }}
        >
          <Icon
            sx={{
              height: smallerGap,
              width: smallerGap,
            }}
            name="ChevronRight"
          />
        </OrangeButton>
      </Stack>
    </Stack>
  );
}
