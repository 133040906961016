import { Stack } from "@mui/material";
import TaskNameField from "../../../common/components/layout/TaskNameField";
import { useLayoutConfig } from "../../../common/context/LayoutConfigProvider";
import { FullStack } from "../../../common/styles/Box.styles";
import { modulesPages } from "../../../routes";
import { defaultGap } from "../../../styles/consts";
import { useTask } from "./context/TaskProvider";

export default function StepWrapper() {
  const { task } = useTask();
  const { moduleName } = useLayoutConfig();

  return (
    <FullStack>
      <TaskNameField />
      <Stack
        sx={{
          width: "100%",
          padding: defaultGap,
          boxSizing: "border-box",
        }}
      >
        {modulesPages[moduleName][task.step]}
      </Stack>
    </FullStack>
  );
}
