import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import s3Service from "../../../../../api/S3Service";
import taskService from "../../../../../api/TaskService";
import { BiggerIcon } from "../../../../../common/components/fundamentals/Icon";
import CircularLoader from "../../../../../common/components/fundamentals/Loader/CircularLoader";
import { OrangeOnHoverStack } from "../../../../../common/styles/Box.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import {
  borderOrange,
  borderStyle,
  defaultGap,
  iconGap,
  tinyGap,
} from "../../../../../styles/consts";
import { useTask } from "../../../MarketingTextTool/context/TaskProvider";

export default function UploadBox({
  value,
  propertyPath,
  module,
}: {
  value: string;
  propertyPath: string;
  module: string;
}) {
  const { saveTask, task } = useTask();
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    value !== "s3://" && value !== "input" ? value.split("/").pop() : undefined
  );
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);

  const uploadFile = async (file: File) => {
    setIsCurrentlyUploading(true);
    const presigned = await s3Service.getPresignedUploadURL();
    await s3Service.uploadPDF(presigned.uploadURL, file);
    saveTask({
      [propertyPath]:
        "s3://" + presigned.uploadURL.split("?")[0].split(".com/")[1],
    });
    setUploadedFileName(presigned.fileName);
    setIsCurrentlyUploading(false);
  };

  const handleClick = (e) => {
    uploadFile(e.target.files[0]);
  };

  const handleDrop = async (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFile(e.dataTransfer.files[0]);
      setDragActive(false);
    }
  };

  const handleDrag = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    }
  };

  const deleteFile = async () => {
    if (uploadedFileName) {
      await taskService.deleteFile(module, task.createdTimestamp);
    }
    saveTask({
      [propertyPath]: "",
    });
    setUploadedFileName(undefined);
  };
  const [isHover, setIsHover] = useState(false);
  return (
    <Box
      id="upload-box"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      component="label"
      sx={{
        width: "100%",
      }}
    >
      {uploadedFileName ? (
        <Stack
          sx={{
            height: "100%",
            gap: tinyGap,
          }}
        >
          <HeadingText>Uploaded files:</HeadingText>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: iconGap,
              justifyContent: "space-between",
            }}
          >
            <Stack sx={{ flexDirection: "row", gap: iconGap }}>
              <BiggerIcon name="RedFile" />
              <Typography>{uploadedFileName}</Typography>
            </Stack>

            <OrangeOnHoverStack id="clear" onClick={deleteFile}>
              <BiggerIcon
                sx={{
                  "&:hover": {
                    cursor: "crosshair",
                  },
                }}
                name="Trash"
              />
            </OrangeOnHoverStack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            border: dragActive ? borderOrange : borderStyle,
            "&:hover": {
              border: borderOrange,
              cursor: "crosshair",
            },
            justifyContent: "center",
            alignItems: "center",
            minHeight: "6.5rem",
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isCurrentlyUploading ? (
            <CircularLoader size={defaultGap} />
          ) : (
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: iconGap,
                filter:
                  dragActive || isHover
                    ? "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)"
                    : "none",
              }}
            >
              <Typography>Upload file</Typography>
              <BiggerIcon name="Folder" />
              <Box
                component="input"
                accept={"application/pdf"}
                type="file"
                hidden
                onChange={handleClick}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
}
