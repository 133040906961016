import { Stack } from "@mui/material";
import { CustomFilterBox } from "../../common/styles/Form.styles";
import { SmallText } from "../../common/styles/Text.styles";
import { checkboxGap } from "../../styles/consts";

export default function TagsOverview({ tags }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: checkboxGap,
        whiteSpace: "nowrap",
        alignItems: "center",
      }}
    >
      {tags.map((option) => (
        <CustomFilterBox key={option}>
          <SmallText>{option}</SmallText>
        </CustomFilterBox>
      ))}
    </Stack>
  );
}
