import { Stack, TextField } from "@mui/material";
import { SmallText } from "../../../common/styles/Text.styles";
import { perelynBlack } from "../../../styles/consts";

export default function PasswordField({
  description,
  name,
  placeholder,
  disabled = false,
  setValue,
  ...props
}) {
  return (
    <Stack>
      <Stack>
        <SmallText color={perelynBlack}>{description}</SmallText>
      </Stack>

      <TextField
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        name={name}
        id={name}
        autoComplete={name}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
    </Stack>
  );
}
