import { Stack, Typography } from "@mui/material";
import { useSnackbar } from "../../common/context/SnackbarProvider";
import { OutlinedButton } from "../../common/styles/Button.styles";
import { HeadingText } from "../../common/styles/Text.styles";
import {
  borderStyle,
  checkboxGap,
  defaultGap,
  iconGap,
  reviewsBoxWidth,
} from "../../styles/consts";

export default function ToolInDevelopmentBox() {
  const { showSnackbar } = useSnackbar();

  const buttons = [
    {
      label: "Request Features",
      id: "request-features",
      function: () => showSnackbar("Feature request has been sent!"),
    },
    {
      label: "Request Early Access",
      id: "request-early-access",
      function: () => showSnackbar("Early access request has been sent!"),
    },
    {
      label: "Download Output Sample",
      id: "download-output",
      function: () => showSnackbar("Output sample is beeing downloaded..."),
      disabled: true,
    },
  ];

  return (
    <Stack
      sx={{
        width: reviewsBoxWidth,
        minWidth: reviewsBoxWidth,
        boxSizing: "border-box",
        border: borderStyle,
        padding: checkboxGap,
        justifyContent: "space-between",
        gap: defaultGap,
        overflow: "hidden",
        backgroundColor: "#FFF5D9",
      }}
    >
      <Stack
        sx={{
          gap: iconGap,
        }}
      >
        <HeadingText>Tool in development</HeadingText>
        <Typography>
          We’re working on this tool at the moment and hope to launch it soon.
          If you have feature requests or want to help us develop the tool
          select from the options below!
        </Typography>
      </Stack>
      <Stack
        sx={{
          gap: checkboxGap,
        }}
      >
        {buttons.map((button) => (
          <OutlinedButton
            onClick={button.function}
            key={button.id}
            sx={{ backgroundColor: "white" }}
            disabled={button.disabled}
          >
            {button.label}
          </OutlinedButton>
        ))}
      </Stack>
    </Stack>
  );
}
