import { Stack } from "@mui/material";

import {
  MediumText,
  SmallText,
} from "../../../../../../common/styles/Text.styles";
import { iconGap, perelynGrey } from "../../../../../../styles/consts";
import ConfigBox from "../ConfigBox";
import WordsLimit from "./SocialMediaSettings/WordsLimit";

export default function LengthSettings() {
  return (
    <ConfigBox>
      <Stack
        sx={{
          gap: iconGap,
        }}
      >
        <MediumText>Length settings</MediumText>
        <SmallText>Specify the length of the generated output*</SmallText>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "2.75rem",
        }}
      >
        <WordsLimit id="min" type={"min"} color={perelynGrey} />
        <WordsLimit id="max" type={"max"} />
      </Stack>
    </ConfigBox>
  );
}
