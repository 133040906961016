import { Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FullStack } from "../../../../../common/styles/Box.styles";
import { OrangeButton } from "../../../../../common/styles/Button.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import {
  borderStyle,
  defaultGap,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../../../styles/consts";
import LengthSettings from "./section/LengthSettings";
import MetaTextSettings from "./section/MetaTextSettings";

import agentOneService from "../../../../../api/AgentOneService";
import { useLoading } from "../../../../../common/context/LoadingProvider";
import { dashboardPath, MARKETING_TEXT } from "../../../../../routes";
import { useTask } from "../../context/TaskProvider";
import ConfigSelect from "./ConfigSelect";
import ConfigStep, { ConfigStepType } from "./ConfigStep";
import TopMarketingConfig from "./TopMarketingConfig";
import PromptPresetSettings from "./section/PromptPresetSettings/PromptPresetSettings";
import SocialMediaSettings from "./section/SocialMediaSettings/SocialMediaSettings";

export default function MarketingTextConfigPage() {
  const { task } = useTask();
  const { withLoading } = useLoading();

  const navigate = useNavigate();

  const [currentlyDisplayedConfigStepIdx, setCurrentlyDisplayedConfigStepIdx] =
    useState(0);

  const configSteps: ConfigStepType[] = [
    {
      label: "Social Media",
      component: <SocialMediaSettings />,
    },
    { label: "Length", component: <LengthSettings /> },
    { label: "Prompt preset", component: <PromptPresetSettings /> },
    { label: "Meta text", component: <MetaTextSettings /> },
  ];

  const isLastConfigStep = () =>
    currentlyDisplayedConfigStepIdx === configSteps.length - 1;

  const handleGenerate = async () => {
    if (
      task.userPrompts.marketingText?.use &&
      task.userPrompts.marketingText?.content &&
      task.userPrompts.marketingText?.name
    ) {
      task.configs.marketingText.nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1 =
        task.userPrompts.marketingText.content +
        " The post should be between" +
        task.configs.marketingText.nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1.split(
          "The post should be between"
        )[1];
      task.configs.marketingText.nodes.WriteLinkedinPostNode.promptPresets.value.presetName =
        task.userPrompts.marketingText.name;
    }

    await agentOneService.runMarketingTextGeneration(task);
    navigate(dashboardPath(MARKETING_TEXT));
  };

  return (
    <FullStack>
      <TopMarketingConfig />

      <Stack
        sx={{
          border: borderStyle,
          height: "100%",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            padding: smallerGap,
            flexDirection: "row",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <HeadingText>Select output</HeadingText>
          <ConfigSelect config={task.configs.marketingText} />
        </Stack>
        <FullStack
          sx={{
            flexDirection: "row",
            padding: `3rem ${smallerGap}`,
            boxSizing: "border-box",
          }}
        >
          <FullStack>
            <Stack
              sx={{
                width: "100%",
                boxSizing: "border-box",
                gap: smallerGap,
                paddingLeft: smallerGap,
              }}
            >
              {configSteps.map((step, idx) => (
                <ConfigStep
                  key={idx}
                  step={step}
                  onClick={() => setCurrentlyDisplayedConfigStepIdx(idx)}
                  isSelected={idx <= currentlyDisplayedConfigStepIdx}
                  currentlyDisplayedConfigStepIdx={
                    currentlyDisplayedConfigStepIdx
                  }
                  stepIndex={idx}
                />
              ))}
            </Stack>
          </FullStack>

          {configSteps[currentlyDisplayedConfigStepIdx].component}
        </FullStack>
        <Stack
          sx={{
            flexDirection: "row",
            borderTop: borderStyle,
            width: "100%",
            height: defaultGap,
            justifyContent: "right",
          }}
        >
          <OrangeButton
            id="continueProcess"
            sx={{
              borderLeft: borderStyle,
              padding: `${tinyGap} ${smallGap}`,
            }}
            onClick={async () => {
              if (isLastConfigStep()) {
                withLoading(handleGenerate);
              } else {
                setCurrentlyDisplayedConfigStepIdx((prev) => prev + 1);
              }
            }}
          >
            {isLastConfigStep() ? "Generate" : "Continue"}
          </OrangeButton>
        </Stack>
      </Stack>
    </FullStack>
  );
}
