import { Stack, Typography } from "@mui/material";

import { DisabledDropdown } from "../../../../../common/styles/Box.styles";
import { SmallText } from "../../../../../common/styles/Text.styles";
import {
  borderStyle,
  perelynBlack,
  perelynPrimary,
  smallGap,
  tinyGap,
} from "../../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../../utils";
import ModifyPromptPopup from "../../../../Settings/CustomPrompts/ModifyPromptPopup";
import PopUpBox from "../../components/result/PopUpBox";
import { useArena } from "../ArenaProvider";

export default function ArenaDisabledPromptPresetSection({
  model,
  configModel,
}) {
  const { config } = useArena();
  const customPromptUsed = config.userPrompts[configModel].use === true;
  return (
    <Stack sx={{ gap: smallGap }}>
      <Stack sx={{ gap: smallGap }}>
        <Stack>
          <Typography>Model {model}</Typography>
          <PopUpBox
            button={
              <DisabledDropdown
                sx={{
                  "&&": {
                    border: borderStyle,
                    color: perelynBlack,
                  },
                }}
              >
                {capitalizeFirstLetter(
                  replaceUnderscoreForWhitespace(
                    customPromptUsed
                      ? config.userPrompts[configModel].name
                      : config.configs[configModel].nodes.WriteLinkedinPostNode
                          .promptPresets.value.presetName
                  )
                )}
              </DisabledDropdown>
            }
            heading={"View prompt A"}
          >
            <Stack
              sx={{
                border: borderStyle,
                height: "100%",
                padding: tinyGap,
              }}
            >
              {customPromptUsed
                ? config.userPrompts[configModel].content
                : config.configs[
                    configModel
                  ].nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1.split(
                    "The post should be between"
                  )[0]}
            </Stack>
          </PopUpBox>
        </Stack>

        <PopUpBox
          button={
            <SmallText
              sx={{
                textDecoration: "underline",
                cursor: "crosshair",
                ":hover": {
                  color: perelynPrimary,
                },
              }}
            >
              Save this prompt
            </SmallText>
          }
          heading={"Create new prompt"}
        >
          {(setIsOpen) => (
            <ModifyPromptPopup
              isArena={
                customPromptUsed
                  ? config.userPrompts[configModel].content
                  : config.configs[
                      configModel
                    ].nodes.WriteLinkedinPostNode.promptPresets.value.prompts.systemPrompt1.split(
                      "The post should be between"
                    )[0]
              }
              setIsOpen={setIsOpen}
            />
          )}
        </PopUpBox>
      </Stack>
    </Stack>
  );
}
