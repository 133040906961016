import { Stack } from "@mui/material";
import NotImplemented from "../../../../../common/components/utils/NotImplemented";
import {
  OrangeButton,
  OutlinedButton,
} from "../../../../../common/styles/Button.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import {
  iconGap,
  perelynPrimary,
  smallGap,
  tinyGap,
} from "../../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../../utils";

export default function ResultTypeHeading({ type }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          gap: iconGap,
        }}
      >
        <HeadingText>Output results:</HeadingText>
        <HeadingText color={perelynPrimary}>
          {capitalizeFirstLetter(type)}
        </HeadingText>
      </Stack>
      <Stack
        sx={{
          gap: tinyGap,
          flexDirection: "row",
        }}
      >
        <NotImplemented>
          <OutlinedButton
            sx={{
              padding: `${iconGap} ${smallGap}`,
            }}
          >
            Check later
          </OutlinedButton>
        </NotImplemented>
        <NotImplemented popupLeft>
          <OrangeButton
            sx={{
              padding: `${iconGap} ${smallGap}`,
            }}
          >
            Confirm all & Continue
          </OrangeButton>
        </NotImplemented>
      </Stack>
    </Stack>
  );
}
