import { SxProps } from "@mui/material";
import { iconSize, smallerGap, taskCheckbox } from "../../../styles/consts";
import Image from "./Image";

export default function Icon({
  name,
  sx = {
    height: iconSize,
    width: iconSize,
  },
}: {
  name: string;
  sx?: SxProps;
}) {
  return <Image src={`icons/${name}`} sx={sx} />;
}

export function BiggerIcon({
  name,
  onClick,
  sx = {
    height: taskCheckbox,
    width: taskCheckbox,
  },
}: {
  name: string;
  onClick?: () => void;
  sx?: SxProps;
}) {
  return (
    <Image
      name={name}
      onClick={onClick}
      src={`icons/${name}`}
      sx={{
        cursor: onClick ? "crosshair" : "default",
        ...sx,
      }}
    />
  );
}

export function ActiveIcon({
  active = false,
  name = "XSquare",
  onClick,
  sx = {
    height: smallerGap,
    width: smallerGap,
  },
}: {
  active?: boolean;
  name?: string;
  onClick?: () => void;
  sx?: SxProps;
}) {
  return (
    <Image
      onClick={onClick}
      src={`icons/${name}`}
      sx={{
        cursor: onClick ? "crosshair" : "default",
        filter: active
          ? "brightness(0) saturate(100%) invert(73%) sepia(35%) saturate(7033%) hue-rotate(107deg) brightness(101%) contrast(103%)"
          : "brightness(0) saturate(100%) invert(49%) sepia(21%) saturate(4190%) hue-rotate(339deg) brightness(101%) contrast(101%)",
        ...sx,
      }}
    />
  );
}

export function MusicIcon({
  name,
  onClick,
  sx = {
    height: "1.5rem",
    width: "1.5rem",
  },
}: {
  name: string;
  onClick?: () => void;
  sx?: SxProps;
}) {
  return (
    <Image
      name={name}
      onClick={onClick}
      src={`icons/${name}`}
      sx={{
        ...sx,
      }}
    />
  );
}
