import { Theme } from "@emotion/react";
import { createTheme } from "@mui/material";
import {
  borderGrey,
  borderOrange,
  borderStyle,
  defaultTransition,
  mediumTextFieldHeight,
  perelynBlack,
  perelynGrey,
  perelynPrimary,
  smallGap,
  tinyGap,
} from "./styles/consts";

declare module "@mui/material/styles" {
  interface Palette {
    grey: Palette["grey"];
  }

  interface PaletteOptions {
    grey?: PaletteOptions["grey"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
  interface TextFieldPropsColorOverrides {
    grey: true;
  }
}

export const theme: Theme = createTheme({
  palette: {
    text: {
      primary: perelynBlack,
      secondary: perelynPrimary,
    },
    primary: {
      main: perelynPrimary,
      light: "white",
      dark: perelynBlack,
      contrastText: perelynBlack,
    },
    secondary: {
      main: "#white",
      dark: perelynPrimary,
      contrastText: perelynBlack,
    },
    error: {
      main: "#F97066",
      600: "#D92D20",
    },
    success: {
      main: "#32D583",
    },
  },
  typography: {
    fontFamily: "ABCWhyte",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiStack: {
      styleOverrides: {
        root: {
          input: {},
          "*::-webkit-scrollbar": {
            width: tinyGap,
          },

          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "white",
            border: borderStyle,
          },
          fontSize: "0.875rem",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#242424",
          fontSize: "0.875rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          boxShadow: "0",
          height: "2.25rem",
          cursor: "crosshair",
          whiteSpace: "nowrap",
          transition: defaultTransition,
          color: perelynBlack,
          padding: `${tinyGap} ${smallGap}`,

          "&:hover": {
            backgroundColor: perelynPrimary,
          },
          "&:disabled": {
            backgroundColor: "white",
            color: perelynGrey,
            border: borderGrey,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            borderRadius: "0",
            border: borderOrange,
            height: "fit-content",
            transition: "all .4s linear",
            color: perelynPrimary,
            boxShadow: "none",
            // "0px 0px 0px 0px rgba(0, 0, 0, 0.2), -1px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
              {
                webkitBackgroundColor: "text",
                webkitTextFieldColor: "black",
                transition: "background-color 5000s ease-in-out 0s",
                boxShaodw: "inset 0 0 20px 20px transparent",
              },
          },
          "*::-webkit-scrollbar": {
            width: tinyGap,
          },

          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "white",
            border: borderStyle,
          },
          "& .MuiInputBase-root": {
            borderRadius: "0",
            backgroundColor: "white",
            border: "none",
            height: mediumTextFieldHeight,
            transition: "all 100ms linear",
          },
          "& .MuiInputBase-multiline": {
            height: "fit-content",
          },
          "& .MuiOutlinedInput-root": {
            transition: "all 100ms linear",

            "& fieldset": {
              transition: "all 100ms linear",
              border: borderStyle,
            },
            "&:hover fieldset": {
              transition: "all 100ms linear",
              border: `1px solid ${perelynPrimary}`,
            },
            "&.Mui-focused fieldset": {
              transition: "all 100ms linear",
              border: `1px solid ${perelynPrimary}`,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "2.25rem",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: "40px",
        },
      },
    },
  },
});
