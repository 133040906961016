import { Stack, TextField, Typography } from "@mui/material";
import Icon, {
  BiggerIcon,
} from "../../../../../common/components/fundamentals/Icon";
import NotImplemented from "../../../../../common/components/utils/NotImplemented";
import { useSnackbar } from "../../../../../common/context/SnackbarProvider";
import {
  FullStack,
  GreyOnHoverStack,
} from "../../../../../common/styles/Box.styles";
import {
  OrangeButton,
  OutlinedButton,
  TransparentBigButton,
} from "../../../../../common/styles/Button.styles";
import { HeadingText } from "../../../../../common/styles/Text.styles";
import {
  borderDashed,
  borderStyle,
  checkboxGap,
  defaultGap,
  defaultTransition,
  iconGap,
  miniGap,
  perelynGrey,
  perelynPrimary,
  smallerGap,
  tinyGap,
} from "../../../../../styles/consts";
import PromptSettingsTag from "../../../MarketingTextTool/components/result/PromptSettingsTag";
import ResultTypeHeading from "../../../MarketingTextTool/components/result/ResultTypeHeading";

const usedPrompt = `Create a mesmerizing abstract background inspired by the interplay of vibrant colors and dynamic shapes. Think about the emotions you want to convey through this artwork and let your creativity flow.`;

export default function ImageGenerationResultsPage() {
  const { showSnackbar } = useSnackbar();
  return (
    <FullStack>
      <Stack
        sx={{
          gap: smallerGap,
        }}
      >
        <ResultTypeHeading type="Image" />
        <Stack
          sx={{
            width: "100%",
            border: borderStyle,
          }}
        >
          {/* <ResultSection
            type={"Social media"}
            label={selectedParam}
            results={marketingTextResultsList}
            isLast={true}
          /> */}

          {/* Delete what's below later */}

          <Stack>
            <Stack
              sx={{
                padding: smallerGap,
                boxSizing: "border-box",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: borderDashed,
              }}
            >
              <Stack
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                  gap: checkboxGap,
                }}
              >
                <HeadingText>Image Generation</HeadingText>
                <BiggerIcon
                  sx={{
                    height: "1.5rem",
                    width: "1.5rem",
                  }}
                  name="MinusCircle"
                />
                <BiggerIcon
                  sx={{
                    height: "1.5rem",
                    width: "1.5rem",
                  }}
                  name="MinusCircle"
                />
              </Stack>
              <GreyOnHoverStack>
                <Icon
                  sx={{
                    height: defaultGap,
                    width: defaultGap,
                    transition: defaultTransition,
                  }}
                  name="BigChevronUp"
                />
              </GreyOnHoverStack>
            </Stack>

            <Stack
              sx={{
                padding: `${defaultGap} ${smallerGap} ${smallerGap}`,
                gap: defaultGap,
                borderBottom: "none",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: iconGap,
                  }}
                >
                  <HeadingText>Image Generation</HeadingText>
                  <HeadingText
                    sx={{
                      color: perelynPrimary,
                    }}
                  >
                    Background
                  </HeadingText>
                </Stack>
                <Icon
                  sx={{
                    height: defaultGap,
                    width: defaultGap,
                  }}
                  name="MinusCircle"
                />
              </Stack>
              <Stack
                sx={{
                  gap: smallerGap,
                }}
              >
                <Stack
                  sx={{
                    height: "30rem",
                    width: "100%",
                    border: borderStyle,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HeadingText color={perelynPrimary}>
                    GENERETAED IMAGE HERE
                  </HeadingText>
                </Stack>
                <Stack
                  sx={{
                    gap: tinyGap,
                  }}
                >
                  <Typography>Used Prompt</Typography>
                  <TextField multiline maxRows={5} value={usedPrompt} />
                </Stack>
              </Stack>
              <Stack
                sx={{
                  gap: "0.5rem",
                }}
              >
                <Typography>Prompt settings</Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: smallerGap,
                    }}
                  >
                    <TransparentBigButton
                      sx={{
                        border: borderStyle,
                        width: defaultGap,
                        height: defaultGap,
                        maxHeight: defaultGap,
                      }}
                      disabled
                    >
                      <BiggerIcon name="Plus" />
                    </TransparentBigButton>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: miniGap,
                      }}
                    >
                      <PromptSettingsTag type={"Image Generation"} />
                      <PromptSettingsTag type={"Background"} />
                    </Stack>
                    <NotImplemented>
                      <OutlinedButton
                        sx={{
                          padding: `${tinyGap} ${checkboxGap}`,
                          gap: "0.5rem",
                        }}
                      >
                        <BiggerIcon name="OrangeRefresh" />
                        Retry
                      </OutlinedButton>
                    </NotImplemented>
                  </Stack>
                  <NotImplemented>
                    <OutlinedButton
                      sx={{
                        borderColor: perelynGrey,
                        padding: `${tinyGap} 0rem ${tinyGap} ${checkboxGap}`,
                        color: perelynGrey,
                      }}
                    >
                      Expert mode
                      <Icon
                        sx={{
                          height: "2rem",
                          width: "2rem",
                        }}
                        name="BigChevronDown"
                      />
                    </OutlinedButton>
                  </NotImplemented>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    gap: miniGap,
                    flexDirection: "row",
                  }}
                >
                  <Typography>Image Generation</Typography>
                  <Typography color={perelynPrimary}>Background</Typography>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: checkboxGap,
                  }}
                >
                  <OutlinedButton
                    onClick={() => {
                      navigator.clipboard.writeText(usedPrompt);
                      showSnackbar("Copied to clipboard!");
                    }}
                  >
                    <BiggerIcon name="Copy" />
                  </OutlinedButton>

                  <NotImplemented>
                    <OutlinedButton>Forward</OutlinedButton>
                  </NotImplemented>
                  <NotImplemented>
                    <OutlinedButton>Check later</OutlinedButton>
                  </NotImplemented>
                  <NotImplemented>
                    <OrangeButton>Mark as done</OrangeButton>
                  </NotImplemented>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </FullStack>
  );
}
