import { prepareResponse } from "../utils";
import apiAxios from "./axios";


class BackgroundMusicService {
    async getPresignedUploadURL(): Promise<string> {
        return prepareResponse(
          await apiAxios.get<string>(
            "/background-music"
          )
        );
                    
      }
}

const backgroundMusicService = new BackgroundMusicService();

export default backgroundMusicService;
