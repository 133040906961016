import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { BrowserRouter } from "react-router-dom";
import Layout from "./common/components/layout/Layout";
import LoadingProvider from "./common/context/LoadingProvider";
import translations from "./translations.json";

export default function App() {
  i18next.use(initReactI18next).init({
    resources: translations,
    lng: localStorage.getItem("language") || "de",
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <BrowserRouter>
      <LoadingProvider>
        <Layout />
      </LoadingProvider>
    </BrowserRouter>
  );
}
