import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { OrangeButton } from "../../../common/styles/Button.styles";
import { SmallText } from "../../../common/styles/Text.styles";
import {
  borderGrey,
  borderStyle,
  defaultTransition,
  perelynGrey,
  perelynLightRed,
  perelynPrimary,
  smallerGap,
  tinyGap,
} from "../../../styles/consts";
import Capabilities from "./Capabilities";
import Compliance from "./Compliance";
import FAQ from "./FAQ";
import Status from "./Status";

export type TabLabel = "Capabilities" | "Compliance" | "Status" | "FAQ";

export type TabType = {
  label: TabLabel;
  component: JSX.Element;
};

export default function InfoBoxOverview({ capabilities }) {
  const [currentlyDisplayedTab, setCurrentlyDisplayedTab] =
    useState<TabLabel>("Capabilities");

  const tabs: TabType[] = [
    {
      label: "Capabilities",
      component: <Capabilities capabilities={capabilities} />,
    },
    { label: "Compliance", component: <Compliance /> },
    { label: "Status", component: <Status /> },
    { label: "FAQ", component: <FAQ /> },
  ];

  const getCurrentComponent = () => {
    const currentTab = tabs.find((tab) => tab.label === currentlyDisplayedTab);
    return currentTab ? currentTab.component : null;
  };

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          gap: tinyGap,
          justifyContent: "left",
        }}
      >
        {tabs.map((tab, idx) => (
          <Stack
            key={idx}
            sx={{
              position: "relative",
            }}
          >
            <Stack
              key={tab.label}
              sx={{
                padding: tinyGap,
                border:
                  tab.label === currentlyDisplayedTab
                    ? borderStyle
                    : borderGrey,
                borderBottom: "none",
                width: "6rem",
                alignItems: "center",
                cursor: "crosshair",
                transition: defaultTransition,
                ":hover": {
                  backgroundColor:
                    tab.label !== currentlyDisplayedTab
                      ? perelynLightRed
                      : "none",
                },
              }}
              onClick={() => setCurrentlyDisplayedTab(tab.label)}
            >
              <SmallText
                sx={{
                  transition: defaultTransition,
                  color:
                    tab.label === currentlyDisplayedTab
                      ? perelynPrimary
                      : perelynGrey,
                }}
              >
                {tab.label}
              </SmallText>
            </Stack>
            {tab.label === currentlyDisplayedTab && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-1px",
                  width: "calc(100% - 2px)",
                  height: "1px",
                  backgroundColor: "white",
                  marginLeft: "1px",
                }}
              />
            )}
          </Stack>
        ))}
      </Stack>
      <Stack
        sx={{
          border: borderStyle,
          height: "100%",
          padding: smallerGap,
        }}
      >
        {getCurrentComponent()}
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "right",
          border: borderStyle,
          borderTop: "none",
        }}
      >
        <OrangeButton
          sx={{
            borderLeft: borderStyle,
          }}
        >
          More
        </OrangeButton>
      </Stack>
    </Stack>
  );
}
