import { createContext, useContext, useState } from "react";
import { User } from "../../../common/models/Models";

type UsersState = {
  users: User[];
  setUsers: (users: User[]) => void;
};

const UsersContext = createContext<UsersState | undefined>(undefined);

export const useUsers = (): UsersState => {
  const usersState = useContext(UsersContext);
  if (!usersState) {
    throw new Error("useUsers must be used within a UsersProvider");
  }
  return usersState;
};

export default function UsersProvider({
  children,
  preFetchedValues,
}: {
  children: JSX.Element;
  preFetchedValues: [User[]];
}) {
  const [users, setUsers] = useState<User[]>(preFetchedValues[0]);

  const usersState: UsersState = {
    users,
    setUsers,
  };

  return (
    <UsersContext.Provider value={usersState}>{children}</UsersContext.Provider>
  );
}
