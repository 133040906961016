import { PartialTaskParams, Task } from "../common/models/Models";
import { prepareResponse } from "../utils";
import apiAxios from "./axios";

const TASK_URL = "/task";
const TASKS_URL = "/tasks";

const getURLWithCreatedTimestamp = (url: string, createdTimestamp: string) =>
  `${url}/${createdTimestamp}`;

class TaskService {
  async createTask(type: string): Promise<Task> {
    return prepareResponse(await apiAxios.post<Task>(TASK_URL, { type }));
  }

  async deleteTask(createdTimestamp: string) {
    return prepareResponse(
      await apiAxios.delete<any>(
        getURLWithCreatedTimestamp(TASK_URL, createdTimestamp)
      )
    );
  }

  async deleteTasks(createdTimestamps: string[]) {
    return prepareResponse(
      await apiAxios.delete<any>(
        TASKS_URL + "?createdTimestamps=" + createdTimestamps.join(",")
      )
    );
  }

  async getTask(createdTimestamp: string): Promise<Task> {
    return prepareResponse(
      await apiAxios.get<Task>(
        getURLWithCreatedTimestamp(TASK_URL, createdTimestamp)
      )
    );
  }

  async updateTask(
    createdTimestamp: string,
    taskPropertiesToUpdate: PartialTaskParams
  ): Promise<Task> {
    return prepareResponse(
      await apiAxios.put<Task>(
        getURLWithCreatedTimestamp(TASK_URL, createdTimestamp),
        taskPropertiesToUpdate
      )
    );
  }

  async getTasks(type: string): Promise<Task[]> {
    return prepareResponse(await apiAxios.get<Task[]>(TASKS_URL + (type ? ("?type=" + type) : "")));
  }

  async deleteFile(module: string, createdTimestamp: string) {
    return prepareResponse(
      await apiAxios.delete<any>(
        getURLWithCreatedTimestamp(`/file/${module}`, createdTimestamp),
      )
    );
  }
}

const taskService = new TaskService();
export default taskService;
