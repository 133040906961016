import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import taskService from "../../api/TaskService";
import { BiggerIcon } from "../../common/components/fundamentals/Icon";
import SearchBar from "../../common/components/layout/SearchBar";
import { ItemRow } from "../../common/components/table/Row";
import Table from "../../common/components/table/Table";
import { useLayoutConfig } from "../../common/context/LayoutConfigProvider";
import { useLoading } from "../../common/context/LoadingProvider";
import { useUser } from "../../common/context/UserProvider";
import { usePollTasks } from "../../common/hooks/usePollTasks";
import { Task } from "../../common/models/Models";
import { FullStack } from "../../common/styles/Box.styles";
import { CustomCheckbox } from "../../common/styles/Form.styles";
import { SmallText } from "../../common/styles/Text.styles";
import { checkboxGap, hideScrollbar, smallerGap } from "../../styles/consts";
import {
  formatUnixTimestamp,
  getNestedProperty,
  getUserInitials,
} from "../../utils";
import TaskStatus from "./components/TaskStatus";
import { useTasksDashboard } from "./context/TasksDashboardProvider";

export default function TasksDashboard({ basePath, processStatusParam }) {
  const { tasks, setTasks } = useTasksDashboard();
  const { user } = useUser();
  const { moduleName: moduleType } = useLayoutConfig();
  const { withLoading } = useLoading();
  const navigate = useNavigate();
  usePollTasks();

  const handleTaskDelete = async (task: Task) => {
    await taskService.deleteTask(task.createdTimestamp);
    setTasks(await taskService.getTasks(moduleType));
  };

  const handleGoToTask = async (task: Task) => {
    navigate(`${basePath}/${task.createdTimestamp}`);
  };

  const getTaskRow = (task: Task): ItemRow[] => {
    function ClickableStack({
      children,
    }: {
      children: JSX.Element[] | JSX.Element;
    }) {
      return (
        <Stack
          sx={{
            width: "100%",
            cursor: "crosshair",
          }}
          onClick={() => handleGoToTask(task)}
        >
          {children}
        </Stack>
      );
    }
    return [
      {
        sortParam: "name",
        label: "Task",
        component: (
          <ClickableStack>
            <Typography>{task.name || "Task name"}</Typography>
            {/* <SmallText>Folder name</SmallText> */}
          </ClickableStack>
        ),
        width: "30%",
        minWidth: "7rem",
      },
      {
        sortParam: processStatusParam,
        label: "Status",
        component: (
          <ClickableStack>
            <TaskStatus task={task} />
          </ClickableStack>
        ),
        width: "20%",
        minWidth: "6rem",
      },
      {
        sortParam: "createdTimestamp",
        label: "Date",
        component: (
          <ClickableStack>
            <Typography>
              {formatUnixTimestamp(task.createdTimestamp)}
            </Typography>
          </ClickableStack>
        ),
        width: "20%",
        minWidth: "6rem",
      },
      {
        label: "Users",
        component: (
          <Stack
            sx={{
              flexDirection: "row",
              gap: checkboxGap,
              alignItems: "center",
            }}
          >
            <CustomCheckbox
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SmallText>{getUserInitials(user)}</SmallText>
            </CustomCheckbox>
          </Stack>
        ),
        width: "20%",
        minWidth: "6rem",
      },
      {
        component: (
          <Stack
            sx={{
              flexDirection: "row",
              gap: smallerGap,
            }}
          >
            <BiggerIcon
              name="Trash"
              onClick={() => withLoading(() => handleTaskDelete(task))}
              sx={{
                cursor: "crosshair",
              }}
            />
            <BiggerIcon
              name="Edit"
              sx={{
                cursor: "crosshair",
              }}
              onClick={() => handleGoToTask(task)}
            />
          </Stack>
        ),
        width: "10%",
        minWidth: "4rem",
      },
    ];
  };

  return (
    <FullStack
      sx={{
        overflow: "auto",
        ...hideScrollbar,
      }}
    >
      <SearchBar type={"tasks"} />
      <Table<Task>
        filters={[{ value: "All" }, { value: "Unfinished" }, { value: "Done" }]}
        items={tasks}
        idProperty="createdTimestamp"
        deleteAllFunction={(selectedTaskIds) => {
          withLoading(async () => {
            await taskService.deleteTasks(selectedTaskIds);
            setTasks(await taskService.getTasks(moduleType));
          });
        }}
        filterFunction={(value) => {
          if (value === "All") {
            return tasks;
          }
          return tasks.filter((task) => {
            if (value === "Done") {
              return getNestedProperty(task, processStatusParam) === "FINISHED";
            } else {
              return getNestedProperty(task, processStatusParam) !== "FINISHED";
            }
          });
        }}
        getItemRow={getTaskRow}
      />
    </FullStack>
  );
}
