import {
  Box,
  CircularProgress,
  Collapse,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactPasswordChecklist from "react-password-checklist";
import { useNavigate } from "react-router-dom";
import userService from "../../api/UserService";
import MicrosoftButton from "../../common/components/fundamentals/MicrosoftButton";
import { useSnackbar } from "../../common/context/SnackbarProvider";
import { GreyOnHoverStack } from "../../common/styles/Box.styles";
import { PrimaryButton } from "../../common/styles/Button.styles";
import { SmallText, TitleText } from "../../common/styles/Text.styles";
import {
  defaultGap,
  iconGap,
  perelynBlack,
  perelynPrimary,
  smallerGap,
} from "../../styles/consts";
import PasswordField from "../Settings/Password/PasswordField";

export default function Register() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [password, setPasssword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [allFieldsFilled, setAllFieldsFilled] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAllFieldsFilled(Boolean(email && password && reEnteredPassword && name));
  }, [email, password, reEnteredPassword, name]);

  const validateInputs = (): string | null => {
    if (!email || !password || !reEnteredPassword || !name) {
      return "You need to enter all the details in order to sign up";
    }
    if (password !== reEnteredPassword) {
      return "Passwords do not match";
    }
    return null;
  };

  const submitRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errorMessage = validateInputs();
    if (errorMessage) {
      showSnackbar(errorMessage, true, false);
      return;
    }

    try {
      await userService.registerUser(email.toLowerCase(), password, name);
      showSnackbar("Account created successfully!", false);
      navigate("/verify-email", { state: { email, password } });
    } catch (error) {
      showSnackbar("Something went wrong. Try again", true, false);
      setIsLoading(false);
    }
  };

  const isLoadingHandler = () => {
    setIsLoading(true);
  };

  return (
    <Box component="form" onSubmit={submitRegister}>
      <Stack
        sx={{
          width: "22.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0.75rem",
            paddingBottom: "2rem",
          }}
        >
          <TitleText
            sx={{
              textAlign: "center",
              lineHeight: defaultGap,
            }}
          >
            Create an account
          </TitleText>
          <Typography>Please enter your details</Typography>
        </Stack>
        <Stack sx={{ width: "100%", gap: "1.25rem" }}>
          <Stack>
            <SmallText>Email</SmallText>
            <TextField
              name="email"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              placeholder="Enter your email"
            />
          </Stack>
          <Stack>
            <SmallText>Name</SmallText>
            <TextField
              name="name"
              id="name"
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              placeholder="Enter your name"
            />
          </Stack>
          <PasswordField
            type={"password"}
            name={"password"}
            id={"password"}
            description="Enter your password"
            placeholder="Password (8-60 characters)"
            value={password}
            setValue={setPasssword}
          />
          <PasswordField
            type={"password"}
            name={"reEnteredPassword"}
            id={"reEnteredPassword"}
            description="Re-enter password"
            placeholder="Re-enter your password"
            value={reEnteredPassword}
            setValue={setReEnteredPassword}
          />
          <Collapse
            in={!isPasswordValid && (!!password || !!reEnteredPassword)}
          >
            <ReactPasswordChecklist
              rules={[
                "minLength",
                "number",
                "specialChar",
                "capital",
                "lowercase",
                "match",
              ]}
              minLength={8}
              value={password}
              valueAgain={reEnteredPassword}
              invalidColor={perelynPrimary}
              onChange={(validPassword) => {
                setIsPasswordValid(validPassword);
              }}
            />
          </Collapse>
        </Stack>
        <Stack sx={{ width: "100%", gap: smallerGap }}>
          <Stack sx={{ flexDirection: "row", gap: iconGap }}>
            <SmallText>Already got an account?</SmallText>
            <GreyOnHoverStack onClick={() => navigate("/")}>
              <SmallText sx={{ textDecoration: "underline" }}>
                Log in.
              </SmallText>
            </GreyOnHoverStack>
          </Stack>
          <Stack sx={{ gap: "2rem" }}>
            <PrimaryButton
              disabled={!allFieldsFilled || !isPasswordValid}
              type="submit"
              sx={{
                fontSize: "1rem",
                "&&": {
                  width: "9.5rem",
                  height: "2.75rem",
                },
                ":hover": {
                  "& .MuiCircularProgress-root": {
                    color: perelynPrimary,
                  },
                },
              }}
              onClick={isLoadingHandler}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{ color: perelynBlack }}
                  size={smallerGap}
                />
              ) : (
                "Create account"
              )}
            </PrimaryButton>
            <MicrosoftButton />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
