import { useEffect, useRef } from "react";
import arenaService from "../../api/ArenaService";

import { useArena } from "../../pages/Modules/MarketingTextTool/Arena/ArenaProvider";
import { wait } from "../../utils";

export function usePollArena(shouldPoll = true, delay = 10, times = 10) {
  const { config, setConfig } = useArena();
  const shouldPollRef = useRef(shouldPoll);

  useEffect(() => {
    shouldPollRef.current = shouldPoll;
  }, [shouldPoll]);

  useEffect(() => {
    const pollArena = async () => {
      for (let i = 0; i < times; i++) {
        if (!shouldPollRef.current) {
          break;
        }
        const arenaData = await arenaService.getArena(config.createdTimestamp);
        setConfig(arenaData);
        if (
          arenaData.processStatuses?.marketingTextA?.result &&
          arenaData.processStatuses?.marketingTextB?.result
        ) {
          break;
        }
        await wait(delay);
      }
    };

    if (shouldPoll) {
      pollArena();
    }

    return () => {
      shouldPollRef.current = false;
    };
  }, [shouldPoll, delay, times, config.createdTimestamp, setConfig]);
}
