import { useUser } from "../../../../../common/context/UserProvider";
import DropdownWithLabel from "../../components/config/section/SocialMediaSettings/DropdownWithLabel";
import { useArena } from "../ArenaProvider";

export default function ArenaCustomPromptPresetDropdown({
  configModel,
  model,
}) {
  const { config, saveArena } = useArena();
  const { user } = useUser();

  return (
    <DropdownWithLabel
      isColumn
      label={`Custom preset ${model}`}
      options={user.prompts ? Object.keys(user.prompts) : []}
      value={config.userPrompts[configModel]?.name ?? ""}
      onChange={(value) => {
        const newSelectedPromptPreset = user.prompts
          ? user.prompts[value]
          : null;
        if (newSelectedPromptPreset) {
          saveArena({
            [`userPrompts.${configModel}.name`]: value,
            [`userPrompts.${configModel}.content`]: newSelectedPromptPreset,
          });
        }
      }}
    />
  );
}
