import { Stack } from "@mui/material";
import { useState } from "react";
import Dropdown from "../../../../common/components/fundamentals/Dropdown";
import { DisabledDropdown } from "../../../../common/styles/Box.styles";
import { defaultGap, smallGap } from "../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../utils";
import ArenaAutoUpdateTextField from "./ArenaAutoUpdateTextField";
import ArenaContextCustomization from "./ArenaContextCustomization";
import { useArena } from "./ArenaProvider";

export default function ArenaUserPrompt({ isDisabled }) {
  const { config, saveArena } = useArena();
  const [inputSourceType, setInputSourceType] = useState(
    config.configs.marketingTextA.general.inputSource.type
  );

  const textFieldProps = {
    user_prompt: {
      placeholder: "Enter user prompt*",
      multiline: true,
      rows: 10,
    },
    website: {
      placeholder: "Enter website URL*",
    },
    s3: {
      placeholder: "NOT WORKING YET",
      disabled: true,
    },
  };
  return (
    <Stack
      sx={{
        width: "100%",
        gap: smallGap,
      }}
    >
      <Stack
        sx={{
          width: "100%",
          gap: defaultGap,
        }}
      >
        {isDisabled ||
        !!config.processStatuses?.marketingTextA?.result ||
        !!config.processStatuses?.marketingTextB?.result ? (
          <DisabledDropdown>
            {capitalizeFirstLetter(
              replaceUnderscoreForWhitespace(
                config.configs.marketingTextA.general.inputSource.type
              )
            )}
          </DisabledDropdown>
        ) : (
          <Dropdown
            initialSelected={inputSourceType}
            options={config.configs.marketingTextA.general.inputSource.type_options.map(
              (o) => {
                return {
                  label: capitalizeFirstLetter(
                    replaceUnderscoreForWhitespace(o)
                  ),
                  value: o,
                };
              }
            )}
            onChange={async (value) => {
              setInputSourceType(value);
              const c = await saveArena({
                "configs.marketingTextA.general.inputSource.type": value,
                "configs.marketingTextB.general.inputSource.type": value,
              });
              setInputSourceType(
                c.configs.marketingTextA.general.inputSource.type
              );
            }}
          />
        )}

        <ArenaAutoUpdateTextField
          disabled={
            isDisabled ||
            !!config.processStatuses?.marketingTextA?.result ||
            !!config.processStatuses?.marketingTextB?.result
          }
          id="userPrompt"
          defaultValue={
            config.configs.marketingTextA.general.inputSource.value === "input"
              ? ""
              : config.configs.marketingTextA.general.inputSource.value
          }
          propPath={[
            "configs.marketingTextA.general.inputSource.value",
            "configs.marketingTextB.general.inputSource.value",
          ]}
          sx={{
            width: "100%",
          }}
          {...textFieldProps[inputSourceType]}
        />
      </Stack>
      <Stack
        sx={{
          justifyContent: "space-between",
          gap: smallGap,
        }}
      >
        <ArenaAutoUpdateTextField
          disabled={
            isDisabled ||
            !!config.processStatuses?.marketingTextA?.result ||
            !!config.processStatuses?.marketingTextB?.result
          }
          id="comment"
          placeholder="Add a comment*"
          defaultValue={
            config.configs.marketingTextA.nodes.WriteLinkedinPostNode.comments
              .value === null
              ? ""
              : config.configs.marketingTextA.nodes.WriteLinkedinPostNode
                  .comments.value
          }
          propPath={[
            "configs.marketingTextA.nodes.WriteLinkedinPostNode.comments.value",
            "configs.marketingTextB.nodes.WriteLinkedinPostNode.comments.value",
          ]}
          multiline
          maxRows="3"
          sx={{
            width: "100%",
          }}
        />
        <ArenaContextCustomization isDisabled={isDisabled} />
      </Stack>
    </Stack>
  );
}
