import React, { createContext, useContext, useState } from "react";
import CustomPopUp from "../components/utils/CustomPopUp";

type PopUpState = {
  showPopUpWindow: (
    action: () => void,
    heading: string,
    content: string,
    button1: string,
    button2: string
  ) => void;
};

const PopUpContext = createContext<PopUpState>({
  showPopUpWindow: () => {},
});

export const usePopUp = (): PopUpState => {
  const popUpState = useContext(PopUpContext);
  if (!popUpState) {
    throw new Error("useLoading must be used within a LayoutConfigProvider");
  }
  return popUpState;
};

export default function PopUpProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [action, setAction] = useState(() => () => {});
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [content, setContent] = useState("");
  const [heading, setHeading] = useState("");
  const [button1, setButton1] = useState("Cancel");
  const [button2, setButton2] = useState("");

  const showPopUp = (
    action: () => void,
    heading: string,
    content: string,
    button1: string,
    button2: string
  ) => {
    setAction(() => action);
    setIsPopUpOpen(true);
    setContent(content);
    setHeading(heading);
    setButton1(button1);
    setButton2(button2);
  };

  const popUpState: PopUpState = {
    showPopUpWindow: showPopUp,
  };

  return (
    <PopUpContext.Provider value={popUpState}>
      <>{children}</>
      <CustomPopUp
        action={action}
        heading={heading}
        isPopUpOpen={isPopUpOpen}
        content={content}
        setPopUpOpen={setIsPopUpOpen}
        button1={button1}
        button2={button2}
      />
    </PopUpContext.Provider>
  );
}
