import { Collapse, Stack, TextField, Typography } from "@mui/material";
import Checkbox from "../../../../common/components/fundamentals/Checkbox";
import { MediumText } from "../../../../common/styles/Text.styles";
import { borderStyle, checkboxGap, smallGap } from "../../../../styles/consts";
import { useArena } from "./ArenaProvider";

export default function ArenaOutputResults({
  version,
  value,
  iC,
  setIC,
  setOtherIC,
}: {
  version: string;
  value: string;
  iC: boolean;
  setIC: (value: boolean) => void;
  setOtherIC: (value: boolean) => void;
}) {
  const { config, saveArena } = useArena();

  const handleVoteClick = () => {
    saveArena({
      "feedback.vote": version,
    });
    setIC(!iC);
    setOtherIC(false);
  };

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <MediumText>Result {version}</MediumText>
      <Stack
        sx={{
          gap: smallGap,
        }}
      >
        <TextField id={`Result${version}`} multiline rows={20} value={value} />
        <Collapse
          in={
            !!config.processStatuses?.marketingTextA?.result ||
            !!config.processStatuses?.marketingTextB?.result
          }
        >
          <Stack sx={{ flexDirection: "row", gap: checkboxGap }}>
            <Checkbox
              id={`Vote${version}`}
              iconName="CheckboxX"
              sx={{
                border: borderStyle,
              }}
              isChecked={iC}
              onChange={handleVoteClick}
            />
            <Typography>Vote for {version}</Typography>
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
}
