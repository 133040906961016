import styled from "@emotion/styled";
import { Button } from "@mui/material";
import {
  borderStyle,
  boxHeight,
  defaultTransition,
  perelynBlack,
  perelynDarkGrey,
  perelynGrey,
  perelynPrimary,
} from "../../styles/consts";

export const TransparentBigButton = styled(Button)(
  () =>
    ({
      color: perelynBlack,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      boxSizing: "border-box",
      height: boxHeight,
      cursor: "crosshair",
      transition: "background-color 0.4s, padding .3s",
      "&:hover": {
        backgroundColor: perelynPrimary,
        "& > *": { cursor: "crosshair" },
      },
    } as any)
);

export const OrangeBigButton = styled(Button)(
  () =>
    ({
      color: perelynBlack,
      backgroundColor: perelynPrimary,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      boxSizing: "border-box",
      height: boxHeight,
      cursor: "crosshair",
      transition: "background-color 0.4s, padding .3s",
      "&:hover": {
        backgroundColor: perelynBlack,
        color: perelynPrimary,
        "& > *": {
          filter:
            "brightness(0) saturate(100%)invert(52%) sepia(12%) saturate(2492%) hue-rotate(314deg) brightness(96%) contrast(144%)",
          cursor: "crosshair",
        },
      },
    } as any)
);

export const TransparentSmallButton = styled(Button)(
  () =>
    ({
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      boxSizing: "border-box",
      height: "50%",
      minWidth: "0",
      cursor: "crosshair",
      transition: defaultTransition,
      "&:hover": {
        backgroundColor: perelynPrimary,
      },
    } as any)
);

export const PrimaryButton = styled(Button)(() => ({
  color: perelynBlack,
  backgroundColor: perelynPrimary,
  border: borderStyle,
  justifyContent: "center",
  width: "fit-content",
  "&:hover": {
    color: perelynPrimary,
    backgroundColor: perelynBlack,
  },
  "&:disabled": {
    backgroundColor: perelynGrey,
    color: perelynDarkGrey,
    border: borderStyle,
  },
}));

export const OutlinedButton = styled(Button)(() => ({
  color: perelynBlack,
  border: borderStyle,
  justifyContent: "center",
  width: "fit-content",

  "&:hover": {
    backgroundColor: perelynPrimary,
    "& > *": {
      transition: defaultTransition,
      filter:
        "brightness(0) saturate(100%) invert(11%) sepia(0%) saturate(0%) hue-rotate(131deg) brightness(92%) contrast(90%)",
    },
  },
}));

export const NavigationButton = styled(Button)(() => ({
  color: perelynBlack,
  border: borderStyle,
  "&:hover": {
    color: perelynBlack,
    backgroundColor: perelynPrimary,
  },
}));

export const OrangeButton = styled(Button)(() => ({
  color: perelynBlack,
  backgroundColor: perelynPrimary,
  "&:hover": {
    color: perelynPrimary,
    backgroundColor: perelynBlack,
  },
  "&:disabled": {
    backgroundColor: perelynGrey,
    color: perelynDarkGrey,
  },
}));
