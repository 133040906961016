import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import userService from "../../api/UserService";
import { User } from "../models/Models";

type UserState = {
  user: User;
  setUser: (user: User) => void;
  reloadUser: () => Promise<void>;
};

const UserContext = createContext<UserState | undefined>(undefined);

export const useUser = (): UserState => {
  const userState = useContext(UserContext);
  if (!userState) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return userState;
};

export default function UserProvider({
  children,
  initialUser,
}: {
  children: ReactNode;
  initialUser: User;
}) {
  const [user, setUser] = useState<User>(initialUser);

  useEffect(() => {
    setUser(initialUser);
  }, [initialUser]);

  const reloadUser = async () => {
    setUser(await userService.getUser());
  };

  const userState: UserState = {
    user,
    setUser,
    reloadUser,
  };

  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  );
}
