import { useTask } from "../../../pages/Modules/MarketingTextTool/context/TaskProvider";
import AutoUpdateTextField from "./AutoUpdateTextField";

export default function TaskAutoUpdateTextField({
  defaultValue,
  propPath,
  ...props
}: {
  defaultValue: string;
  propPath: string | string[];
  [key: string]: any;
}) {
  const { saveTask } = useTask();

  return (
    <AutoUpdateTextField
      defaultValue={defaultValue}
      propPath={propPath}
      saveFunction={saveTask}
      {...props}
    />
  );
}
