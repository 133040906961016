import { Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OutlinedButton } from "../../common/styles/Button.styles";
import { HeadingText, SmallText } from "../../common/styles/Text.styles";
import { iconGap, perelynGrey } from "../../styles/consts";

export default function SettingsField({
  label,
  description,
  name,
  defaultValue,
  disabled = false,
  ...props
}) {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        gap: iconGap,
      }}
    >
      <Stack>
        <HeadingText>{label}</HeadingText>
        <SmallText color={perelynGrey}>{description}</SmallText>
      </Stack>
      {name === "password" ? (
        <OutlinedButton
          id="password"
          sx={{
            width: "fit-content",
            justifyContent: "center",
            alignItems: "center",
            gap: iconGap,
          }}
          onClick={() => navigate("/user-settings/change-password")}
        >
          Change password
        </OutlinedButton>
      ) : (
        <TextField
          name={name}
          id={name}
          defaultValue={defaultValue}
          disabled={disabled}
          {...props}
        />
      )}
    </Stack>
  );
}
