import { FullStack } from "../../../common/styles/Box.styles";
import TableColumn from "./TableColumn";

export default function Capabilities({ capabilities }) {
  return (
    <FullStack
      sx={{
        flexDirection: "row",
      }}
    >
      {capabilities.map((column, idx) => (
        <TableColumn
          key={idx}
          heading={column.heading}
          optionsList={column.options}
          isLast={idx === capabilities.length - 1}
        />
      ))}
    </FullStack>
  );
}
