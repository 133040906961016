import { PartialUserParams, User } from "../common/models/Models";
import { prepareResponse } from "../utils";
import tokenService from "./TokenService";
import apiAxios from "./axios";

const LOGIN_URL = `/login`;
const LOGOUT_URL = `/logout`;
const USER_URL = "/user";
const USERS_URL = "/users";
const REGISTER_URL = "/register";

class UserService {
  async login(username: string, password: string) {
    tokenService.setTokens(
      prepareResponse(
        await apiAxios.post<any>(LOGIN_URL, {
          username,
          password,
        })
      )
    );

    return "Logged in successfully!";
  }

  async adminRegisterUser(
    username: string,
    password: string,
    name: string,
    secret: string
  ) {
    try {
      const response = await apiAxios.post<any>("/admin-register", {
        username,
        password,
        name,
        secret,
      });
      return response.data;
    } catch (error) {
      throw new Error("Registration failed");
    }
  }

  async registerUser(
    username: string,
    password: string,
    name: string,
  ) {
    try {
      const response = await apiAxios.post<any>(REGISTER_URL, {
        username,
        password,
        name,
      });
      return response.data;
    } catch (error) {
      throw new Error("Registration failed");
    }
  }

  async verifyEmail(username: string, code: string) {
    return prepareResponse(
      await apiAxios.post<any>("/verify-email", {
        username,
        code,
      })
    );
  }

  async resendVerificationCode(username: string) {
    return prepareResponse(
      await apiAxios.post<any>("/resend-verification-code", {
        username,
      })
    );
  }


  async changePassword(
    currentPassword: string,
    newPassword: string,
    reEnteredPassword: string
  ) {
    return prepareResponse(
      await apiAxios.post<any>("/change-password", {
        currentPassword,
        newPassword,
        reEnteredPassword,
      })
    );
  }

  async forgotPassword(email: string) {
    return prepareResponse(
      await apiAxios.post<any>("/forgot-password", {
        email,
      })
    );
  }

  async resetPassword(code: string, newPassword: string, email: string) {
    return prepareResponse(
      await apiAxios.post<any>("/reset-password", {
        email,
        newPassword,
        code,
        
      })
    );
  }

  async logout() {
    return prepareResponse(await apiAxios.post<any>(LOGOUT_URL));
  }

  async getUser(): Promise<User> {
    return prepareResponse(await apiAxios.get<User>(USER_URL));
  }

  async getUsers() {
    return prepareResponse(await apiAxios.get<User[]>(USERS_URL));
  }

  async updateUser(userPropertiesToUpdate: PartialUserParams, setUser): Promise<User> {
    const user = await prepareResponse(
      await apiAxios.put<User>(USER_URL, userPropertiesToUpdate)
    );
    setUser(user);
    return user;

  }

  async activateUser(Id: string, active: boolean) {
    return prepareResponse(
      await apiAxios.put<User>("/activate-user", { Id, active })
    );
  }

  async deleteUserPrompt(name: string, setUser): Promise<User> {
    const user = await prepareResponse(
      await apiAxios.delete<User>(`/user-prompt/${name}`)
    );
    setUser(user);
    return user;
  }

  async updateUserPrompt(userPropertiesToUpdate: PartialUserParams, setUser): Promise<User> {
    const user = await prepareResponse(
      await apiAxios.put<User>("/user-prompt", userPropertiesToUpdate)
    );
    setUser(user);
    return user;

  }

}

const userService = new UserService();

export default userService;
