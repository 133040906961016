import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EvallmService from "../../../api/EvallmService";
import {
  borderStyle,
  boxHeight,
  defaultGap,
  miniGap,
  perelynGrey,
  perelynPrimary,
  smallGap,
  smallerGap,
  tinyGap,
} from "../../../styles/consts";
import { getFormattedName, getUserInitials, splitMail } from "../../../utils";
import { useUser } from "../../context/UserProvider";
import { OrangeOnHoverStack } from "../../styles/Box.styles";
import {
  MainSidebarComponent,
  MiddleSidebarComponent,
} from "../../styles/Sidebar.styles";
import { HeadingText } from "../../styles/Text.styles";
import Icon from "../fundamentals/Icon";
import Logout from "../user/Logout";

export default function PrimarySidebar() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    async function fetchApiKey() {
      const key = await EvallmService.getEvallm();
      setApiKey(key);
    }
    fetchApiKey();
  }, []);

  const handleUserSettings = () => {
    navigate("/user-settings");
  };
  const handleChangelog = () => {
    navigate("/changelog");
  };

  const handleAdminSettings = async () => {
    navigate("/admin-settings");
  };

  const handleGoToEvalLM = async () => {
    if (!apiKey) {
      const key = await EvallmService.getEvallm();
      setApiKey(key);
    }
    window.location.href = `https://d39rdckp9uuo1v.cloudfront.net/?apiKey=${apiKey}`;
  };

  return (
    <MainSidebarComponent sx={{ borderRight: borderStyle }}>
      <MiddleSidebarComponent>
        <HeadingText>Main menu</HeadingText>
        <Stack sx={{ gap: smallerGap }}>
          <Stack gap={tinyGap}>
            <Typography>Started tasks</Typography>

            {/* <Stack flexDirection="row" alignItems="center" gap={iconGap}>
              <Icon name="RedFile" />
              <OrangeSidebarStack>Task 1</OrangeSidebarStack>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={iconGap}>
              <Icon name="RedFile" />
              <OrangeSidebarStack>Task 2</OrangeSidebarStack>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={iconGap}>
              <Icon name="RedFile" />
              <OrangeSidebarStack>Task 3</OrangeSidebarStack>
            </Stack> */}
            <Typography sx={{ color: perelynGrey }}>See all</Typography>
          </Stack>
          <Stack gap={tinyGap}>
            <Typography>Favorite tools</Typography>
            <Stack
              sx={{
                gap: tinyGap,
              }}
            >
              {/* <Typography>Tool 1</Typography>
              <Typography>Tool 2</Typography>
              <GreySidebarStack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: iconGap,
                }}
              >
                <Icon name="Folder" />
                Folder open
                <Icon name="ChevronUp" />
                <Icon name="DragComponent" />
              </GreySidebarStack>
              <Stack gap={tinyGap}>
                <Typography>Tool 3</Typography>
                <Typography>Tool 4</Typography>
              </Stack> */}
              <Typography sx={{ color: perelynGrey }}>+Add folder</Typography>
            </Stack>
          </Stack>

          <Typography>Submit Idea</Typography>
        </Stack>
        <Stack>
          <Stack sx={{ gap: miniGap, padding: `${defaultGap} 0` }}>
            <OrangeOnHoverStack id="evallm" onClick={handleGoToEvalLM}>
              Go to EvalLM
            </OrangeOnHoverStack>
            <Stack>Feedback</Stack>
            <OrangeOnHoverStack id="changelog" onClick={handleChangelog}>
              Changelog
            </OrangeOnHoverStack>
          </Stack>
          <Stack sx={{ gap: miniGap }}>
            <OrangeOnHoverStack id="settings" onClick={handleUserSettings}>
              <Icon name="Settings" />
              Settings
            </OrangeOnHoverStack>
            {user.profile === "admin" && (
              <OrangeOnHoverStack
                id="admin-settings"
                onClick={handleAdminSettings}
              >
                <Icon name="Settings" />
                Admin Settings
              </OrangeOnHoverStack>
            )}
          </Stack>
        </Stack>
      </MiddleSidebarComponent>

      <Stack
        sx={{
          borderTop: borderStyle,
          flexDirection: "row",
          justifyContent: "space-between",
          height: boxHeight,
          boxSizing: "border-box",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            gap: "0.75rem",
            boxSizing: "border-box",
            padding: `0.75rem 0rem 0.75rem ${smallGap}`,
          }}
        >
          <Stack
            sx={{
              height: "2.5rem",
              width: "2.5rem",
              border: `1px solid ${perelynPrimary}`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: perelynPrimary, fontSize: smallGap }}>
              {getUserInitials(user)}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontSize: "1.125rem" }}>
              {getFormattedName(user.name)}
            </Typography>
            <Typography>{splitMail(user.email)}</Typography>
          </Stack>
        </Stack>
        <Logout />
      </Stack>
    </MainSidebarComponent>
  );
}
