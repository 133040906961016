import { Stack, Typography } from "@mui/material";
import Icon from "../../common/components/fundamentals/Icon";
import { OrangeButton } from "../../common/styles/Button.styles";
import { HeadingText } from "../../common/styles/Text.styles";
import {
  borderStyle,
  checkboxGap,
  iconGap,
  perelynGrey,
  reviewsBoxWidth,
  smallerGap,
  tinyGap,
} from "../../styles/consts";

export default function ReviewsBoxOverview({ status }) {
  return (
    <Stack
      sx={{
        width: reviewsBoxWidth,
        minWidth: reviewsBoxWidth,
        border: borderStyle,
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          padding: checkboxGap,
          flexWrap: "wrap",
        }}
      >
        <Stack
          sx={{
            "& > *": {
              color: status === "active" ? "black" : perelynGrey,
            },
          }}
        >
          <HeadingText>Reviews</HeadingText>
          <Typography>No reviews yet</Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            gap: iconGap,
            padding: tinyGap,
            paddingLeft: "none",
          }}
        >
          {Array.from({ length: 5 }).map((_, index) => (
            <Icon
              key={index}
              sx={{
                height: smallerGap,
                width: smallerGap,
                filter:
                  status === "active"
                    ? "none"
                    : "invert(99%) sepia(76%) saturate(17%) hue-rotate(0deg) brightness(93%) contrast(80%)",
              }}
              name="Star"
            />
          ))}
        </Stack>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "right",
          borderTop: borderStyle,
        }}
      >
        <OrangeButton
          disabled
          sx={{
            "&&": {
              borderLeft: borderStyle,

              "&:disabled": {
                borderLeft: borderStyle,
              },
            },
          }}
        >
          See all
        </OrangeButton>
      </Stack>
    </Stack>
  );
}
