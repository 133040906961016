import { Stack } from "@mui/material";
import { BiggerIcon } from "../../../common/components/fundamentals/Icon";
import { SmallText } from "../../../common/styles/Text.styles";
import { borderStyle, iconGap, perelynLightRed } from "../../../styles/consts";

export default function TableColumn({
  optionsList,
  heading,
  isLast,
}: {
  optionsList: string[];
  heading: string;
  isLast?: boolean;
}) {
  return (
    <Stack
      sx={{
        border: borderStyle,
        borderRight: isLast ? borderStyle : "none",
        width: "33%",
      }}
    >
      <Stack
        sx={{
          padding: iconGap,
          borderBottom: borderStyle,
          backgroundColor: perelynLightRed,
        }}
      >
        <SmallText>{heading}</SmallText>
      </Stack>
      <Stack
        sx={{
          height: "100%",
          justifyContent: "space-evenly",
          padding: iconGap,
          gap: iconGap,
        }}
      >
        {optionsList.map((option, idx) => (
          <Stack
            key={idx}
            sx={{ flexDirection: "row", alignItems: "center", gap: iconGap }}
          >
            <BiggerIcon name="OrangeArrowSide" />
            <SmallText>{option}</SmallText>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
