import { useEffect, useRef } from "react";
import taskService from "../../api/TaskService";
import { useTask } from "../../pages/Modules/MarketingTextTool/context/TaskProvider";
import { useTasksDashboard } from "../../pages/Task/context/TasksDashboardProvider";
import { wait } from "../../utils";
import { useLayoutConfig } from "../context/LayoutConfigProvider";

export function usePollTasks(shouldPoll = true, delay = 15, times = 10) {
  const { setTasks } = useTasksDashboard();
  const { moduleName } = useLayoutConfig();
  const shouldPollRef = useRef(shouldPoll);

  useEffect(() => {
    shouldPollRef.current = shouldPoll;
  }, [shouldPoll]);

  useEffect(() => {
    const pollTasks = async () => {
      for (let i = 0; i < times; i++) {
        if (!shouldPollRef.current) {
          break;
        }

        const ts = await taskService.getTasks(moduleName);
        setTasks(ts);
        await wait(delay);
      }
    };

    if (shouldPoll) {
      pollTasks();
    }

    return () => {
      shouldPollRef.current = false;
    };
  }, [delay, times, setTasks, shouldPoll, moduleName]);
}

export function usePollTask(shouldPoll = true, delay = 10, times = 10) {
  const { task, setTask } = useTask();
  const shouldPollRef = useRef(shouldPoll);

  useEffect(() => {
    shouldPollRef.current = shouldPoll;
  }, [shouldPoll]);

  useEffect(() => {
    const pollTask = async () => {
      for (let i = 0; i < times; i++) {
        if (!shouldPollRef.current) {
          break;
        }
        const ts = await taskService.getTask(task.createdTimestamp);
        setTask(ts);
        if (ts.processStatuses.marketingText?.result) {
          break;
        }
        await wait(delay);
      }
    };

    if (shouldPoll) {
      pollTask();
    }

    return () => {
      shouldPollRef.current = false;
    };
  }, [shouldPoll, delay, times, task.createdTimestamp, setTask]);
}
