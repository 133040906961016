import { Tokens } from "../common/models/Models";

class TokenService {
  getAllTokens(): Tokens {
    return JSON.parse(localStorage.getItem("tokens") || "{}");
  }

  setTokens(tokens: Tokens) {
    localStorage.setItem("tokens", JSON.stringify(tokens));
  }

  getToken(token: keyof Tokens) {
    return this.getAllTokens()[token];
  }

  clearTokens() {
    localStorage.removeItem("tokens");
  }

  isAccessTokenPresent() {
    return Boolean(this.getToken("AccessToken"));
  }

}

const tokenService = new TokenService();
export default tokenService;