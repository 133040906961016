import { useTask } from "../../../../context/TaskProvider";
import DropdownWithLabel from "../SocialMediaSettings/DropdownWithLabel";

export default function PromptPresetDropdown() {
  const { task, saveTask } = useTask();
  return (
    <DropdownWithLabel
      label="Prompt preset"
      options={task.configs.marketingText.nodes.WriteLinkedinPostNode.promptPresets.options.map(
        (option) => option.presetName
      )}
      value={
        task.configs.marketingText.nodes.WriteLinkedinPostNode.promptPresets
          .value.presetName
      }
      onChange={(value) => {
        const newSelectedPromptPreset =
          task.configs.marketingText.nodes.WriteLinkedinPostNode.promptPresets.options.find(
            (option) => option.presetName === value
          );
        saveTask({
          "configs.marketingText.nodes.WriteLinkedinPostNode.promptPresets.value":
            newSelectedPromptPreset,
        });
      }}
    />
  );
}
