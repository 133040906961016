import { Collapse, Stack } from "@mui/material";
import { useState } from "react";
import TaskAutoUpdateTextField from "../../../../../../common/components/fundamentals/AutoUpdateTextField";
import Dropdown from "../../../../../../common/components/fundamentals/Dropdown";
import Icon from "../../../../../../common/components/fundamentals/Icon";
import { GreyOnHoverStack } from "../../../../../../common/styles/Box.styles";
import {
  defaultTransition,
  iconGap,
  sidebarWidth,
  smallGap,
} from "../../../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../../../utils";
import { useTask } from "../../../context/TaskProvider";

export default function ContextCustomization() {
  const { task, saveTask } = useTask();
  const [contextType, setContextType] = useState(
    task.configs.marketingText.general.context.value[0].type
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const textFieldProps = {
    text: {
      placeholder: "Enter custom context*",
      multiline: true,
      maxRows: 8,
    },
    website: {
      placeholder: "Enter website URL*",
    },
    s3: {
      placeholder: "NOT WORKING YET",
    },
  };

  return (
    <Stack>
      <Stack sx={{ gap: smallGap }}>
        <GreyOnHoverStack
          sx={{
            flexDirection: "row",
            gap: iconGap,
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          Context Customization{" "}
          <Icon
            name="ChevronUp"
            sx={{
              transition: defaultTransition,
              transform: isOpen ? "none" : "scaleY(-1)",
            }}
          />
        </GreyOnHoverStack>
        <Collapse in={isOpen}>
          <Stack
            sx={{
              gap: smallGap,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Dropdown
              initialSelected={contextType}
              options={task.configs.marketingText.general.context.type_options.map(
                (o) => {
                  return {
                    label: capitalizeFirstLetter(
                      replaceUnderscoreForWhitespace(o)
                    ),
                    value: o,
                  };
                }
              )}
              onChange={async (value) => {
                setContextType(value);
                const t = await saveTask({
                  "configs.marketingText.general.context.value[0].type": value,
                  "configs.marketingMetadata.nodes.MetadataNode.context.type":
                    value,
                });
                setContextType(
                  t.configs.marketingText.general.context.value[0].type
                );
              }}
            />
            <TaskAutoUpdateTextField
              id="taskContext"
              sx={{
                width: "50%",
                minWidth: sidebarWidth,
              }}
              {...textFieldProps[contextType]}
              defaultValue={
                task.configs.marketingText.general.context.value[0].value || ""
              }
              propPath={[
                "configs.marketingText.general.context.value[0].value",
                "configs.marketingMetadata.nodes.MetadataNode.context.value",
              ]}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
}
