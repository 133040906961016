import { TextField } from "@mui/material";
import { useState } from "react";
import organizationService from "../../../api/OrganizationService";
import { useUser } from "../../../common/context/UserProvider";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../common/styles/Button.styles";
import {
  InternalSettingsAreaWithPadding,
  SettingsButtonSection,
} from "../Settings.styles";

export default function NoOrganization() {
  const { reloadUser } = useUser();
  const [organizationName, setOrganizationName] = useState("");

  const reloadOrganization = async () => {
    await reloadUser();
    setOrganizationName("");
  };

  const createOrganization = async () => {
    await organizationService.createOrganization(organizationName);
    await reloadOrganization();
  };

  const joinOrganization = async () => {
    await organizationService.joinOrganization(organizationName);
    await reloadOrganization();
  };
  return (
    <>
      <InternalSettingsAreaWithPadding
        sx={{
          height: "100%",
        }}
      >
        <TextField
          placeholder="Organization Name"
          autoComplete="off"
          value={organizationName}
          onChange={(e) => setOrganizationName(e.target.value)}
        />
      </InternalSettingsAreaWithPadding>
      <SettingsButtonSection>
        <OutlinedButton
          sx={{
            "&&": {
              borderBottom: "none",
              borderTop: "none",
              borderRight: "none",
            },
          }}
          onClick={createOrganization}
        >
          Create
        </OutlinedButton>
        <PrimaryButton
          sx={{
            "&&": {
              borderBottom: "none",
              borderTop: "none",
              borderRight: "none",
            },
          }}
          onClick={joinOrganization}
        >
          Join
        </PrimaryButton>
      </SettingsButtonSection>
    </>
  );
}
