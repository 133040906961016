import { Box, Stack } from "@mui/material";
import { HeadingText } from "../../common/styles/Text.styles";
import { borderStyle, defaultGap } from "../../styles/consts";
import PromptsSettings from "./CustomPrompts/PromptsSettings";
import OrganizationSettings from "./Organization/OrganizationSettingsPage";
import ChangePassword from "./Password/ChangePassword";
import PersonalInformationSetting from "./PersonalInformationSetting";

export default function UserSettings() {
  return (
    <Box>
      <Stack sx={{ padding: `${defaultGap} ${defaultGap} 0` }}>
        <HeadingText>Account information</HeadingText>
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          borderBottom: borderStyle,
          boxSizing: "border-box",
          padding: `${defaultGap} ${defaultGap} 3.75rem`,
          gap: "3.75rem",
        }}
      >
        <PersonalInformationSetting />
        <ChangePassword />
        <OrganizationSettings />
        <PromptsSettings />
      </Stack>
    </Box>
  );
}
