import { Organization, PartialOrganizationParams, User } from "../common/models/Models";
import { prepareResponse } from "../utils";
import apiAxios from "./axios";

const ORGANIZATION_URL = "/organization";
const ORGANIZATION_USERS_URL = "/organization/users";
const ORGANIZATIONS_URL = "/organizations";

const getURLWithName = (url: string, name: string) =>
  `${url}/${name}`;

class OrganizationService {
  async createOrganization(name: string): Promise<Organization> {
    return prepareResponse(await apiAxios.post<Organization>(ORGANIZATION_URL, { name }));
  }

  async deleteOrganization(name: string) {
    return prepareResponse(
      await apiAxios.delete<any>(
        getURLWithName(ORGANIZATION_URL, name)
      )
    );
  }

  async getOrganization(name: string): Promise<Organization> {
    return prepareResponse(
      await apiAxios.get<Organization>(
        getURLWithName(ORGANIZATION_URL, name)
      )
    );
  }

  async updateOrganization(
    name: string,
    organizationPropertiesToUpdate: PartialOrganizationParams
  ): Promise<Organization> {
    return prepareResponse(
      await apiAxios.put<Organization>(
        getURLWithName(ORGANIZATION_URL, name),
        organizationPropertiesToUpdate
      )
    );
  }

  async getOrganizationUsers(name: string): Promise<User[]> {
    return prepareResponse(
      await apiAxios.get<Organization>(
        getURLWithName(ORGANIZATION_USERS_URL, name)
      )
    );
  }

  async joinOrganization(
    name: string,
  ): Promise<Organization> {
    return prepareResponse(
      await apiAxios.post<Organization>(
        getURLWithName(ORGANIZATION_URL, name)
      )
    );
  }

  async getOrganizations(): Promise<Organization[]> {
    return prepareResponse(await apiAxios.get<Organization[]>(ORGANIZATIONS_URL));
  }

  async deleteFile(name: string) {
    return prepareResponse(
      await apiAxios.delete<any>(
        getURLWithName("/file", name),
      )
    );
  }
}

const organizationService = new OrganizationService();
export default organizationService;
