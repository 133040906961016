import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import {
  borderOrange,
  borderStyle,
  defaultGap,
  defaultTransition,
  iconGap,
  sidebarWidth,
  tinyGap,
} from "../../styles/consts";

export const FullStack = styled(Stack)(() => ({
  height: "100%",
  width: "100%",
}));

export const OrangeOnHoverStack = styled(Stack)(
  () =>
    ({
      flexDirection: "row",
      alignItems: "center",
      gap: iconGap,
      transition: defaultTransition,
      "&:hover": {
        filter:
          "brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(1354%) hue-rotate(315deg) brightness(98%) contrast(107%)",
        cursor: "crosshair",
        "& > *": {
          cursor: "crosshair",
        },
      },
    } as any)
);

export const GreyOnHoverStack = styled(Stack)(
  () =>
    ({
      flexDirection: "row",
      alignItems: "center",
      gap: iconGap,
      ":hover": {
        filter:
          "brightness(0) saturate(100%) invert(38%) sepia(0%) saturate(450%) hue-rotate(139deg) brightness(94%) contrast(92%);",
        cursor: "crosshair",
      },
    } as any)
);

export const DisabledDropdown = styled(Stack)(
  () =>
    ({
      border: borderStyle,
      width: sidebarWidth,
      height: defaultGap,
      padding: tinyGap,
      boxSizing: "border-box",
      justifyContent: "center",
      ":hover": { cursor: "crosshair", border: borderOrange },
    } as any)
);
