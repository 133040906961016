import { CircularProgress } from "@mui/material";
import { boxHeight } from "../../../../styles/consts";
import { FullStack } from "../../../styles/Box.styles";

export default function CircularLoader({
  size,
  sx,
}: {
  size?: string;
  sx?: any;
}) {
  return (
    <FullStack
      sx={{
        ...sx,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={!!size ? size : boxHeight} />
    </FullStack>
  );
}
