import { useLocation } from "react-router-dom";
import arenaService from "../../../../api/ArenaService";
import DataLoadingWrapper from "../../../../common/components/utils/DataLoadingWrapper";
import Arena from "./Arena";
import ArenaProvider from "./ArenaProvider";

export default function ArenaWrapper() {
  const location = useLocation();

  const getArenaFromLocation = async () => {
    const createdTimestamp = location.pathname.split("/").at(-1) || "";
    if (createdTimestamp) {
      return arenaService.getArena(createdTimestamp);
    }
  };

  return (
    <DataLoadingWrapper
      ContextProvider={ArenaProvider}
      fetchFunctions={[getArenaFromLocation]}
      observeParam={location.pathname}
    >
      <Arena />
    </DataLoadingWrapper>
  );
}
