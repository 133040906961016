import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import "../../index.css";
import { defaultGap } from "../../styles/consts";

export const TitleText = styled(Typography)(() => ({
  fontSize: defaultGap,
  fontFamily: "ABCWhyte",
}));

export const SubtitleText = styled(Typography)(() => ({
  fontSize: "2rem",
  fontFamily: "ABCWhyte",
}));

export const HeadingText = styled(Typography)(() => ({
  fontSize: "1.5rem",
  fontFamily: "ABCWhyte",
}));

export const BiggerText = styled(Typography)(() => ({
  fontSize: "1.875rem",
  fontFamily: "ABCWhyte",
}));

export const MediumText = styled(Typography)(() => ({
  fontSize: "1.125rem",
  fontFamily: "ABCWhyte",
}));

export const SmallText = styled(Typography)(() => ({
  fontSize: "0.75rem",
  fontFamily: "ABCWhyte",
}));

export const MicroText = styled(Typography)(() => ({
  fontSize: "0.5rem",
  fontFamily: "ABCWhyte",
}));

export const UnderlinedOnHoverText = styled(Typography)(() => ({
  fontFamily: "ABCWhyte",
  cursor: "crosshair",
  ":hover": {
    textDecoration: "underline",
  },
}));
