import { Stack } from "@mui/material";
import Icon from "../../../common/components/fundamentals/Icon";
import { useUser } from "../../../common/context/UserProvider";
import { HeadingText } from "../../../common/styles/Text.styles";
import { borderStyle, iconGap } from "../../../styles/consts";
import {
  InternalSettingsAreaWithPadding,
  SettingsArea,
} from "../Settings.styles";
import NoOrganization from "./NoOrganization";
import OrganizationOverview from "./OrganizationOverview";

export default function OrganizationSettings() {
  const { user } = useUser();
  return (
    <SettingsArea
      sx={{
        "&&": {
          minWidth: "22rem",
          width: "fit-content",
        },
      }}
    >
      <InternalSettingsAreaWithPadding
        sx={{
          borderBottom: borderStyle,
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: iconGap }}>
          <HeadingText>Organization Settings</HeadingText>
          <Icon name="InfoCircle" />
        </Stack>
      </InternalSettingsAreaWithPadding>
      <Stack
        sx={{
          flexGrow: 1,
        }}
      >
        {user.organization ? <OrganizationOverview /> : <NoOrganization />}
      </Stack>
    </SettingsArea>
  );
}
