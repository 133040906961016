import { Stack, Typography } from "@mui/material";
import { SmallText } from "../../common/styles/Text.styles";
import {
  perelynLightGreen,
  perelynLightRed,
  perelynPrimary,
  perelynSuccess,
  tinyGap,
} from "../../styles/consts";

const types = {
  new: {
    backgroundColor: perelynLightGreen,
    color: perelynSuccess,
    label: "New",
  },
  bugfix: {
    backgroundColor: perelynLightRed,
    color: perelynPrimary,
    label: "Bugfix",
  },
  improvement: {
    backgroundColor: "#CEDAFF",
    color: "#3F1DAF",
    label: "Improvement",
  },
};

export default function ChangelogStatus({
  log,
  type,
}: {
  log: string;
  type: keyof typeof types;
}) {
  const selectedType = types[type];
  return (
    <Stack sx={{ gap: tinyGap }}>
      <Stack
        sx={{
          borderRadius: "1rem",
          padding: "0.125rem 0.5rem",
          width: "fit-content",
          backgroundColor: selectedType.backgroundColor,
        }}
      >
        <SmallText
          sx={{
            color: selectedType.color,
          }}
        >
          {selectedType.label}
        </SmallText>
      </Stack>
      <Typography>{log}</Typography>
    </Stack>
  );
}
