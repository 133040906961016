import { Stack, Typography } from "@mui/material";
import Icon from "../../common/components/fundamentals/Icon";
import { MicroText } from "../../common/styles/Text.styles";
import { iconGap, perelynGrey, smallerGap, tinyGap } from "../../styles/consts";

export default function RatingScoreOverview({ status }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: tinyGap,
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          "&&": {
            "& > *": { color: status === "active" ? "black" : perelynGrey },
          },
        }}
      >
        <Typography>Rating</Typography>
        <MicroText>No ratings yet</MicroText>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          gap: iconGap,
        }}
      >
        {Array.from({ length: 5 }).map((_, idx) => (
          <Icon
            key={idx}
            sx={{
              height: smallerGap,
              width: smallerGap,
              filter:
                status === "active"
                  ? "none"
                  : "invert(99%) sepia(76%) saturate(17%) hue-rotate(0deg) brightness(93%) contrast(80%)",
            }}
            name="Star"
          />
        ))}
      </Stack>
    </Stack>
  );
}
