import taskService from "../../api/TaskService";
import Loader from "../../common/components/fundamentals/Loader/Loader";
import DataLoadingWrapper from "../../common/components/utils/DataLoadingWrapper";
import { useLayoutConfig } from "../../common/context/LayoutConfigProvider";
import TasksDashboardProvider from "./context/TasksDashboardProvider";
import TasksDashboard from "./TasksDashboard";

export default function TasksDashboardWrapper({
  basePath,
  processStatusParam,
}: {
  basePath: string;
  processStatusParam: string;
}) {
  const { moduleName } = useLayoutConfig();
  return moduleName !== "" ? (
    <DataLoadingWrapper
      ContextProvider={TasksDashboardProvider}
      fetchFunctions={[() => taskService.getTasks(moduleName)]}
    >
      <TasksDashboard
        basePath={basePath}
        processStatusParam={processStatusParam}
      />
    </DataLoadingWrapper>
  ) : (
    <Loader />
  );
}
