import { Stack, SxProps, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import {
  borderStyle,
  checkboxGap,
  defaultGap,
  defaultTransition,
  hideScrollbar,
  iconGap,
  miniGap,
  perelynPrimary,
  sidebarWidth,
  tinyGap,
} from "../../../styles/consts";
import { flip } from "../../../utils";
import { FullStack } from "../../styles/Box.styles";
import Icon, { BiggerIcon } from "./Icon";

export type Option = {
  label?: string;
  value: string;
  iconName?: string;
};

export default function Dropdown({
  id,
  initialSelected,
  options,
  onChange,
  sx = {},
  zIndexModifier = 0,
}: {
  id?: string;
  initialSelected: string;
  options?: Option[];
  onChange?: (value: string) => any;
  sx?: SxProps;
  zIndexModifier?: number;
}) {
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState<
    string | undefined
  >(initialSelected);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function MenuItem({ option }: { option: Option }) {
    const isCurrentlySelected = currentlySelectedOption === option.value;
    const isCurrentlySelectedProps = isCurrentlySelected
      ? { color: perelynPrimary }
      : {};

    return (
      <Stack
        onClick={(e) => {
          setCurrentlySelectedOption(option.value);
          if (onChange) {
            onChange(option.value);
          }

          setIsOpen(false);
        }}
        sx={{
          alignItems: "center",
          flexDirection: "row",
          gap: iconGap,
          padding: `${miniGap} ${checkboxGap}`,
          height: "2rem",
          cursor: "crosshair",
          transition: defaultTransition,

          "&:hover":
            isCurrentlySelected || !isOpen
              ? {}
              : {
                  backgroundColor: perelynPrimary,
                },
        }}
      >
        <Typography
          sx={{
            whiteSpace: "nowrap",
            ...isCurrentlySelectedProps,
          }}
        >
          {t(option.label ? option.label : option.value)}
        </Typography>
        {option.iconName && <BiggerIcon name={option.iconName} />}
      </Stack>
    );
  }

  const isOpenMenuProps = isOpen
    ? {
        marginTop: defaultGap,

        height: options?.length ? `${options.length * 2}rem` : defaultGap,
        overflowY: "auto",
      }
    : {
        marginTop: "0",
        height: defaultGap,
        overflowY: "hidden",
      };

  const getCurrentlySelectedLabel = () => {
    const currentOptionObj = options?.find(
      (o) => o.value === currentlySelectedOption
    );
    return t(
      (currentOptionObj?.label
        ? currentOptionObj.label
        : currentOptionObj?.value) || ""
    );
  };

  return (
    <Stack
      sx={{
        position: "relative",
        minWidth: sidebarWidth,
        width: "fit-content",
        ...sx,
      }}
    >
      <Stack
        id={id}
        sx={{
          backgroundColor: "white",
          flexDirection: "row",
          width: "100%",
          boxSizing: "border-box",
          height: defaultGap,
          padding: `0 ${tinyGap}`,
          borderRadius: "0px",
          border: borderStyle,
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "crosshair",
          zIndex: 999 + zIndexModifier,
          transition: defaultTransition,
          gap: iconGap,
        }}
        onClick={flip(setIsOpen)}
      >
        <Typography>{getCurrentlySelectedLabel()}</Typography>

        <Icon
          name="DropdownChevron"
          sx={{
            transition: defaultTransition,
            width: "1.5rem",
            transform: isOpen ? "none" : "scaleY(-1)",
          }}
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "white",
          position: "absolute",
          borderRight: borderStyle,
          borderLeft: borderStyle,
          borderBottom: borderStyle,
          transition: defaultTransition,
          boxSizing: "border-box",
          zIndex: 997 + zIndexModifier,
          ...hideScrollbar,
          ...isOpenMenuProps,
        }}
      >
        {options && options.map((o, idx) => <MenuItem option={o} key={idx} />)}
      </Stack>
      {isOpen && (
        <FullStack
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 995 + zIndexModifier,
          }}
          onClick={() => setIsOpen(false)}
        />
      )}
    </Stack>
  );
}
