import TaskAutoUpdateTextField from "../../../../../common/components/fundamentals/TaskAutoUpdateTextField";
import { UnderlinedOnHoverText } from "../../../../../common/styles/Text.styles";
import { useTask } from "../../context/TaskProvider";
import PopUpBox from "../result/PopUpBox";

export default function PromptCustomization() {
  const { task } = useTask();
  return (
    <PopUpBox
      button={<UnderlinedOnHoverText>Customize prompt</UnderlinedOnHoverText>}
      heading={`Customize ${task.userPrompts.marketingText?.name}`}
    >
      <TaskAutoUpdateTextField
        defaultValue={task.userPrompts.marketingText?.content || ""}
        propPath={"userPrompts.marketingText.content"}
        multiline
        rows={13}
        sx={{
          width: "100%",
        }}
      />
    </PopUpBox>
  );
}
