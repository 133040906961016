import styled from "@emotion/styled";
import { OutlinedInput, Stack } from "@mui/material";
import {
  borderOrange,
  borderStyle,
  defaultTransition,
  perelynPrimary,
  smallTextFieldHeight,
  smallTextFieldWidth,
  smallerCheckbox,
  smallerGap,
  tinyGap,
} from "../../styles/consts";

export const getForceSize = (size: string) => ({
  height: size,
  width: size,
  minHeight: size,
  minWidth: size,
  maxHeight: size,
  maxWidth: size,
});

export const CustomCheckbox = styled(Stack)(() => ({
  ...getForceSize(smallerGap),
  border: borderStyle,
}));

export const CustomSmallerCheckbox = styled(Stack)(() => ({
  ...getForceSize(smallerCheckbox),
  border: borderStyle,
}));

export const TaskStatusIcon = styled(Stack)(() => ({
  borderRadius: "1rem",
  padding: "0.125rem 0.5rem",
  width: "fit-content",
}));

export const CustomNumberInput = styled(OutlinedInput)(() => ({
  width: smallTextFieldWidth,
  height: smallTextFieldHeight,
  borderRadius: "0",
}));

export const CustomFilterBox = styled(Stack)(() => ({
  border: borderStyle,
  padding: tinyGap,
  justifyContent: "center",
  alignItems: "center",
  transition: defaultTransition,
  ":hover": {
    border: borderOrange,
    cursor: "crosshair",
    "& > *": {
      color: perelynPrimary,
    },
  },
}));
