import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { defaultGap, smallerGap } from "../../../styles/consts";
import Dropdown from "../fundamentals/Dropdown";
import { BiggerIcon } from "../fundamentals/Icon";

export default function FilteringArea({
  filters,
  items,
  filterFunction,
  setFilteredItems,
  setPage,
  deleteAllFunction,
  anySelected,
  deletable,
}: {
  filters: any[];
  items: any[];
  filterFunction: (value: string) => any[];
  setFilteredItems: any;
  setPage: any;
  deleteAllFunction: () => void;
  anySelected: boolean;
  deletable: boolean;
}) {
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>(
    filters[0].value
  );

  useEffect(() => {
    setFilteredItems(filterFunction(selectedDropdownValue));
  }, [items, filterFunction, selectedDropdownValue, setFilteredItems]);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: `0 ${defaultGap} ${defaultGap} 0`,
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: smallerGap,
        }}
      >
        {/* <Stack
          sx={{
            flexDirection: "row",
            gap: "0.5rem",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            padding: `${tinyGap} ${smallGap}`,
            border: borderStyle,
          }}
        >
          <Typography>Date</Typography>
          <BiggerIcon name="XMark" />
        </Stack> */}
        {anySelected && deletable && (
          <BiggerIcon
            name="Trash"
            sx={{
              height: "100%",
            }}
            onClick={deleteAllFunction}
          />
        )}
      </Stack>

      {filters && (
        <Dropdown
          id="tableFilters"
          options={filters}
          initialSelected={selectedDropdownValue}
          onChange={(value) => {
            setSelectedDropdownValue(value);
            setFilteredItems(filterFunction(value));
            setPage(1);
          }}
        />
      )}
    </Stack>
  );
}
