import { prepareResponse } from "../utils";
import apiAxios from "./axios";


class EvallmService {
 async getEvallm(): Promise<string> {
    return prepareResponse(
        await apiAxios.get<string>(
            "/evallm"
            )
    )
  }
}

const evallmService = new EvallmService();

export default evallmService;