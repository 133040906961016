import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack } from "@mui/material";
import { useState } from "react";
import {
  borderStyle,
  defaultTransition,
  iconGap,
  miniGap,
} from "../../../styles/consts";
import { FullStack } from "../../styles/Box.styles";
import { SmallText } from "../../styles/Text.styles";

export default function NotImplemented({
  onLeft = false,
  popupLeft = false,
  children,
}: {
  onLeft?: boolean;
  popupLeft?: boolean;
  children: JSX.Element[] | JSX.Element;
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [showHoverPopup, setShowHoverPopup] = useState(false);
  const styles =
    showPopup || showHoverPopup
      ? {
          opacity: 1,
          zIndex: 9999999,
        }
      : {
          opacity: 0,
          zIndex: -1,
          pointerEvents: "none",
        };
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: miniGap,
        alignItems: "start",
        position: "relative",
      }}
    >
      {!onLeft && children}
      <ErrorOutlineIcon
        sx={{
          height: "1rem",
          cursor: "crosshair",
        }}
        onClick={() => setShowPopup(true)}
        onMouseEnter={() => setShowHoverPopup(true)}
        onMouseLeave={() => setShowHoverPopup(false)}
      />
      {onLeft && children}

      <Stack
        sx={{
          position: "absolute",
          top: "-1rem",
          left: popupLeft ? "-2%" : onLeft ? "-60%" : "105%",
          width: "9rem",
          transition: defaultTransition,
          ...styles,
          backgroundColor: "white",
          padding: iconGap,
          border: borderStyle,
        }}
      >
        <SmallText>This feature hasn't been implemented yet</SmallText>
      </Stack>
      {showPopup && (
        <FullStack
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 999999,
          }}
          onClick={() => setShowPopup(false)}
        />
      )}
    </Stack>
  );
}
