import { Stack } from "@mui/material";
import Loader from "../../../../../common/components/fundamentals/Loader/Loader";
import { usePollTask } from "../../../../../common/hooks/usePollTasks";
import { FullStack } from "../../../../../common/styles/Box.styles";
import { borderStyle, smallerGap } from "../../../../../styles/consts";
import { useTask } from "../../context/TaskProvider";
import ResultSection, { Result } from "./ResultSection";
import ResultTypeHeading from "./ResultTypeHeading";

export default function MarketingTextResultsPage() {
  const { task, selectedParam } = useTask();
  const marketingTextResults = task?.processStatuses.marketingText?.result;
  const marketingTextResultsList: Result[] = [];
  const translationsList = Object.entries(
    marketingTextResults?.translations || {}
  );
  const isOnlyEnglish = translationsList.length === 0;
  if (isOnlyEnglish) {
    marketingTextResultsList.push({
      label: "English",
      value: (marketingTextResults?.articles || {})[selectedParam],
    });
  } else {
    translationsList.forEach(([key, value]: any) => {
      marketingTextResultsList.push({ label: key, value: value });
    });
  }
  usePollTask(!Boolean(marketingTextResults));

  return (
    <FullStack>
      {marketingTextResults ? (
        <Stack
          sx={{
            gap: smallerGap,
          }}
        >
          <ResultTypeHeading type={selectedParam} />
          <Stack
            sx={{
              width: "100%",
              border: borderStyle,
            }}
          >
            <ResultSection
              type={"Metadata"}
              label={selectedParam}
              basePropPath="processStatuses.marketingMetadata.result.metadata.metadata"
              results={
                Object.entries(
                  task?.processStatuses.marketingMetadata?.result?.metadata
                    .metadata || {}
                )
                  .filter(([key, value]) =>
                    Array.isArray(value) ? value.length !== 0 : Boolean(value)
                  ) // Filter the list if value is empty
                  .map(([key, value]) => ({
                    label: key,
                    value: Array.isArray(value) ? value.join(", ") : value,
                  })) ?? []
              }
              isShort
            />
            <ResultSection
              type={"Social media"}
              label={selectedParam}
              basePropPath="processStatuses.marketingText.result.translations"
              results={marketingTextResultsList}
              isLast={true}
              isOnlyEnglish={isOnlyEnglish}
            />
          </Stack>
        </Stack>
      ) : (
        <Loader label="Still processing..." />
      )}
    </FullStack>
  );
}
