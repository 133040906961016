import { useUser } from "../../../../../../../common/context/UserProvider";
import { useTask } from "../../../../context/TaskProvider";
import DropdownWithLabel from "../SocialMediaSettings/DropdownWithLabel";

export default function CustomPromptPresetDropdown() {
  const { task, saveTask } = useTask();
  const { user } = useUser();

  return (
    <DropdownWithLabel
      label="Custom preset"
      options={user.prompts ? Object.keys(user.prompts) : []}
      value={task.userPrompts.marketingText?.name ?? ""}
      onChange={(value) => {
        const newSelectedPromptPreset = user.prompts
          ? user.prompts[value]
          : null;
        if (newSelectedPromptPreset) {
          saveTask({
            "userPrompts.marketingText.name": value,
            "userPrompts.marketingText.content": newSelectedPromptPreset,
          });
        }
      }}
    />
  );
}
