import { Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Icon, { BiggerIcon } from "../../common/components/fundamentals/Icon";
import Image from "../../common/components/fundamentals/Image";
import { useSnackbar } from "../../common/context/SnackbarProvider";
import { useUser } from "../../common/context/UserProvider";
import { PrimaryButton } from "../../common/styles/Button.styles";
import { SmallText, SubtitleText } from "../../common/styles/Text.styles";
import {
  borderStyle,
  checkboxGap,
  defaultGap,
  iconGap,
  perelynMediumRed,
  perelynPrimary,
  smallerGap,
} from "../../styles/consts";

export default function ModuleDescriptionBox({
  heading,
  description,
  status,
}: {
  heading: string;
  description: string;
  status?: string;
}) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const { user } = useUser();

  const handleInterestedClick = () => {
    showSnackbar(
      "Marked as interested! We will let you know once it's available."
    );
  };
  const handleTestTool = () => {
    if (user.active === true) {
      navigate(location.pathname + "/dashboard");
    } else {
      showSnackbar(
        "Your account needs to be activated by administrator in order to access this tool.",
        true,
        false
      );
    }
  };
  return (
    <Stack
      sx={{
        border: borderStyle,
        width: status === "active" ? "50%" : "100%",
        justifyContent: "space-between",
      }}
    >
      <Stack>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Image
            sx={{
              padding: "0.5rem",
            }}
            src="YLogo"
          />
          {status === "active" && (
            <Stack
              sx={{
                flexDirection: "row",
              }}
            >
              <Stack
                sx={{
                  height: defaultGap,
                  width: defaultGap,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                  name="EuAiAct"
                />
              </Stack>

              <Stack
                sx={{
                  height: defaultGap,
                  width: defaultGap,
                  borderLeft: borderStyle,
                  borderBottom: borderStyle,
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
              >
                <BiggerIcon name={"GreenCheckSquare"} />
              </Stack>
            </Stack>
          )}
          {status === "inDevelopment" && (
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: iconGap,
                padding: iconGap,
                borderLeft: borderStyle,
                borderBottom: borderStyle,
              }}
            >
              <BiggerIcon name="YellowFrenchKey" />
              <SmallText>In development</SmallText>
            </Stack>
          )}
          {status === "idea" && (
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: iconGap,
                padding: iconGap,
                borderLeft: borderStyle,
                borderBottom: borderStyle,
              }}
            >
              <BiggerIcon name="BlueLightBulb" />
              <SmallText>Idea</SmallText>
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            padding: `${checkboxGap} ${checkboxGap} ${smallerGap}`,
          }}
        >
          <SubtitleText>{heading}</SubtitleText>
          <Typography>{description}</Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          borderTop: borderStyle,
          justifyContent: "right",
        }}
      >
        <PrimaryButton
          id={status === "active" ? "test-tool" : "interested-button"}
          sx={{
            "&&": {
              borderTop: "none",
              borderRight: "none",
              borderBottom: "none",
              backgroundColor:
                status === "active" ? perelynPrimary : perelynMediumRed,
            },
          }}
          onClick={status === "active" ? handleTestTool : handleInterestedClick}
        >
          {status === "active" ? "Test tool" : "Mark me as interested"}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
