import { Box, Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import {
  iconGap,
  perelynError,
  perelynSuccess,
  sidebarWidth,
  smallerGap,
} from "../../../styles/consts";
import { GreyOnHoverStack } from "../../styles/Box.styles";
import { BiggerIcon } from "../fundamentals/Icon";

export default function CustomSnackbar({
  open,
  message,
  setOpen,
  error = false,
  autoClose = true,
}: {
  open: boolean;
  message: string;
  setOpen: (value: boolean) => void;
  error?: boolean;
  autoClose?: boolean;
}) {
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (open && autoClose) {
      const timer = setTimeout(handleClose, 5000);
      return () => clearTimeout(timer);
    }
  }, [open, autoClose, handleClose]);

  const style = error
    ? {
        color: perelynError,
        border: `1px solid ${perelynError}`,
        backgroundColor: "#FFEBEA",
      }
    : {
        color: perelynSuccess,
        border: `1px solid ${perelynSuccess}`,
        backgroundColor: "#E4FAF0",
      };

  return (
    <Box
      sx={{
        ...style,
        position: "fixed",
        top: smallerGap,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 99999,
        padding: "0.5rem",
        display: open ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "20rem",
        width: "fit-content",
        minWidth: sidebarWidth,
        textAlign: "center",
        textWrap: "wrap",
      }}
    >
      <Stack>{message}</Stack>
      <GreyOnHoverStack sx={{ padding: iconGap }} onClick={handleClose}>
        <BiggerIcon
          name="XMark"
          sx={{
            height: smallerGap,
          }}
        />
      </GreyOnHoverStack>
    </Box>
  );
}
