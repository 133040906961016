import { useEffect, useState } from "react";
import LoadingProvider from "../../context/LoadingProvider";
import Loader from "../fundamentals/Loader/Loader";

export default function DataLoadingWrapper({
  children,
  ContextProvider,
  fetchFunctions,
  observeParam,
}: {
  children: React.ReactNode;
  ContextProvider: any;
  fetchFunctions: (() => Promise<any>)[];
  observeParam?: any;
}) {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const results = await Promise.all(fetchFunctions.map((func) => func()));
      setData(results);
    };
    fetchData();
  }, [fetchFunctions, observeParam]);

  return data.length > 0 ? (
    <ContextProvider preFetchedValues={data}>
      <LoadingProvider>{children}</LoadingProvider>
    </ContextProvider>
  ) : (
    <Loader />
  );
}
