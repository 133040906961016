import { Stack } from "@mui/material";
import { Children } from "react";
import { borderStyle, defaultGap } from "../../styles/consts";

export default function ChangelogBox({ children }) {
  return (
    <Stack
      sx={{
        width: "100%",
        boxSizing: "border-box",
        height: "fit-content",
        border: borderStyle,
      }}
    >
      {Children.map(children, (child, idx) => {
        return (
          <Stack
            sx={{
              paddingLeft: defaultGap,
              paddingTop: idx === 0 ? defaultGap : "none",
              boxSizing: "border-box",
            }}
          >
            {child}
          </Stack>
        );
      })}
    </Stack>
  );
}
