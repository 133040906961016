import { OutlinedInput, Stack } from "@mui/material";
import { borderStyle, defaultGap, tinyGap } from "../../../styles/consts";
import { PrimaryButton } from "../../styles/Button.styles";
import NotImplemented from "../utils/NotImplemented";

export default function SearchBar({ type }) {
  return (
    <Stack
      sx={{
        borderBottom: borderStyle,
        width: "100%",
        padding: defaultGap,
        boxSizing: "border-box",
      }}
    >
      <Stack sx={{ flexDirection: "row" }}>
        <OutlinedInput
          fullWidth
          sx={{
            borderRadius: "0",
            height: defaultGap,
          }}
        />
        <NotImplemented popupLeft>
          <PrimaryButton
            sx={{
              padding: `${tinyGap} ${defaultGap}`,
            }}
          >
            Search {type}
          </PrimaryButton>
        </NotImplemented>
      </Stack>
    </Stack>
  );
}
