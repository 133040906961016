import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import userService from "../../api/UserService";
import { useSnackbar } from "../../common/context/SnackbarProvider";
import { useUser } from "../../common/context/UserProvider";
import {
  OrangeButton,
  OutlinedButton,
} from "../../common/styles/Button.styles";
import {
  InternalSettingsAreaWithPadding,
  SettingsArea,
  SettingsButtonSection,
} from "./Settings.styles";
import SettingsField from "./SettingsField";

export default function PersonalInformationSetting() {
  const { user, setUser } = useUser();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const submitSaveChanges = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name")?.toString();
    const context = data.get("context")?.toString();
    if (!name) {
      showSnackbar("Name field cannot be empty!", true);
      return;
    }
    try {
      await userService.updateUser({ name, context }, setUser);
      showSnackbar("Changes saved successfully!", false);
      navigate(-1);
    } catch (error) {
      showSnackbar("Failed to save changes. Please try again.", true, false);
    }
  };

  const settingsFields = [
    {
      label: "Name",
      description: "You can change your name here",
      name: "name",
      defaultValue: user.name,
    },
    {
      label: "Email",
      name: "email",
      defaultValue: user.email,
      disabled: true,
    },
    {
      label: "User Context",
      description: "Change your default context here",
      placeholder: "Enter your context",
      name: "context",
      defaultValue: user.context,
      multiline: true,
      maxRows: 8,
    },
  ];
  return (
    <Box component="form" onSubmit={submitSaveChanges}>
      <SettingsArea>
        <InternalSettingsAreaWithPadding>
          {settingsFields.map((sf, idx) => (
            <SettingsField
              label={sf.label}
              description={sf.description}
              name={sf.name}
              defaultValue={sf.defaultValue}
              key={idx}
              disabled={sf.disabled}
              multiline={sf.multiline}
              maxRows={sf.maxRows}
              placeholder={sf.placeholder}
            />
          ))}
        </InternalSettingsAreaWithPadding>
        <SettingsButtonSection>
          <OutlinedButton
            sx={{
              "&&": {
                borderBottom: "none",
                borderTop: "none",
              },
            }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </OutlinedButton>
          <OrangeButton id="save" type="submit">
            Save changes
          </OrangeButton>
        </SettingsButtonSection>
      </SettingsArea>
    </Box>
  );
}
