import React, { createContext, useContext, useState } from "react";
import Loader from "../components/fundamentals/Loader/Loader";

type LoadingState = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  withLoading: (action: () => Promise<void> | void) => Promise<void>;
};

const LoadingContext = createContext<LoadingState | undefined>(undefined);

export const useLoading = (): LoadingState => {
  const loadingState = useContext(LoadingContext);
  if (!loadingState) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return loadingState;
};

export default function LoadingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const withLoading: LoadingState["withLoading"] = async (action) => {
    try {
      setIsLoading(true);
      return await action();
    } finally {
      setIsLoading(false);
    }
  };

  const loadingState: LoadingState = {
    isLoading,
    setIsLoading,
    withLoading,
  };

  return (
    <LoadingContext.Provider value={loadingState}>
      {!isLoading ? <>{children}</> : <Loader />}
    </LoadingContext.Provider>
  );
}
