import { Stack } from "@mui/material";
import Icon from "../../../../../common/components/fundamentals/Icon";
import { MediumText } from "../../../../../common/styles/Text.styles";
import {
  borderOrange,
  borderStyle,
  perelynLightRed,
  smallerGap,
  tinyGap,
} from "../../../../../styles/consts";

export type ConfigStepType = {
  label: string;
  component: JSX.Element;
};

export default function ConfigStep({
  step,
  onClick,
  isSelected = false,
  currentlyDisplayedConfigStepIdx,
  stepIndex,
}: {
  step: ConfigStepType;
  onClick: () => void;
  isSelected: boolean;
  currentlyDisplayedConfigStepIdx: number;
  stepIndex: number;
}) {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: tinyGap }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: tinyGap,
          cursor: "crosshair",
          boxSizing: "border-box",
        }}
        onClick={onClick}
      >
        <Stack
          sx={{
            height: smallerGap,
            width: smallerGap,
            border: borderStyle,
            transition: "all .4s",
            "&: hover": {
              backgroundColor: perelynLightRed,
            },
          }}
        >
          {isSelected && (
            <Icon
              sx={{
                width: "100%",
                height: "100%",
              }}
              name="CheckboxX"
            />
          )}
        </Stack>
        <MediumText sx={{ textWrap: "nowrap" }}>{step.label}</MediumText>
      </Stack>
      {currentlyDisplayedConfigStepIdx === stepIndex && (
        <Stack
          sx={{
            flexDirection: "row",
            border: borderOrange,
            borderBottom: "none",
            width: "100%",
          }}
        />
      )}
    </Stack>
  );
}
