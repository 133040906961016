import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import userService from "../../../api/UserService";
import Image from "../../../common/components/fundamentals/Image";
import {
  borderStyle,
  boxHeight,
  defaultGap,
  sidebarWidth,
  smallGap,
} from "../../../styles/consts";
import { getFirstPathPart } from "../../../utils";
import { useLayoutConfig } from "../../context/LayoutConfigProvider";
import { useSnackbar } from "../../context/SnackbarProvider";
import {
  TransparentBigButton,
  TransparentSmallButton,
} from "../../styles/Button.styles";
import { TitleText } from "../../styles/Text.styles";
import Icon from "../fundamentals/Icon";
import MusicPlayer from "../utils/MusicPlayer";

export default function Navbar({ loginPage = false }: { loginPage?: boolean }) {
  const { layoutConfig } = useLayoutConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleBackToToolsOverviewClick = () => {
    navigate(getFirstPathPart(location.pathname));
  };

  const handleBackToWebsiteClick = () => {
    window.location.href = "https://www.perelyn.com/";
  };

  const handleUseTool = async () => {
    const u = await userService.getUser();
    if (u.active === true) {
      navigate(location.pathname + "/dashboard");
    } else {
      showSnackbar(
        "Your account needs to be activated by administrator in order to access this tool.",
        true,
        false
      );
    }
  };

  const handleSignUpForNews = () => {
    showSnackbar("Signed up for tool news! We will keep you updated.");
  };

  return (
    <Stack
      sx={{
        borderBottom: borderStyle,
        height: boxHeight,
        minHeight: boxHeight,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          height: "100%",
        }}
      >
        <TransparentBigButton
          sx={{
            paddingLeft: defaultGap,
            width: sidebarWidth,
            "&&": {
              justifyContent: "start",
            },
          }}
          onClick={handleLogoClick}
        >
          <Image src={loginPage ? "PerelynLogo" : "FyndrLogo"} />
        </TransparentBigButton>
        <Stack
          sx={{
            borderLeft: borderStyle,
            height: "100%",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: defaultGap,
          }}
        >
          <TitleText
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {!loginPage && layoutConfig.heading}
          </TitleText>
        </Stack>
      </Stack>

      <Stack
        sx={{
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {layoutConfig.useToolVisible && (
          <TransparentBigButton
            id="use-tool"
            sx={{
              padding: `0 ${smallGap}`,
              borderLeft: borderStyle,
            }}
            onClick={handleUseTool}
          >
            Use Tool
          </TransparentBigButton>
        )}
        {layoutConfig.signUpForNewsVisible && (
          <TransparentBigButton
            id="sign-up-for-news"
            sx={{
              padding: `0 ${smallGap}`,
              borderLeft: borderStyle,
            }}
            onClick={handleSignUpForNews}
          >
            Sign up for tool news
          </TransparentBigButton>
        )}

        {layoutConfig.addToFavoriteVisible && (
          <Stack
            sx={{
              height: "100%",
              width: boxHeight,
              justifyContent: "center",
              alignItems: "center",
              borderLeft: borderStyle,
            }}
          >
            <Icon name="Heart" sx={{ height: "1.5rem", width: "1.5rem" }} />
          </Stack>
        )}
        {layoutConfig.backToVisible && (
          <TransparentBigButton
            sx={{
              padding: `0 ${smallGap}`,
              borderLeft: borderStyle,
            }}
            onClick={
              loginPage
                ? handleBackToWebsiteClick
                : handleBackToToolsOverviewClick
            }
          >
            Back to {loginPage ? "website" : "tools overview"}
          </TransparentBigButton>
        )}
        {!loginPage && <MusicPlayer />}

        <Stack
          sx={{ height: "100%", width: "2.5rem", borderLeft: borderStyle }}
        >
          <TransparentSmallButton
            onClick={handleBackToWebsiteClick}
            sx={{
              "&&": {
                transition: "all 0s",
              },
            }}
          >
            <Icon name="Globe" sx={{ height: "1.5rem", width: "1.5rem" }} />
          </TransparentSmallButton>
          <TransparentSmallButton
            sx={{
              "&&": {
                transition: "all 0s",
              },
              borderTop: borderStyle,
            }}
          >
            <Icon name="Magnifier" sx={{ height: "1.5rem", width: "1.5rem" }} />
          </TransparentSmallButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
