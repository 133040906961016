import { Collapse, Stack } from "@mui/material";
import { useState } from "react";
import Dropdown from "../../../../common/components/fundamentals/Dropdown";
import Icon from "../../../../common/components/fundamentals/Icon";
import {
  DisabledDropdown,
  GreyOnHoverStack,
} from "../../../../common/styles/Box.styles";
import {
  defaultTransition,
  iconGap,
  sidebarWidth,
  smallGap,
} from "../../../../styles/consts";
import {
  capitalizeFirstLetter,
  replaceUnderscoreForWhitespace,
} from "../../../../utils";
import ArenaAutoUpdateTextField from "./ArenaAutoUpdateTextField";
import { useArena } from "./ArenaProvider";

export default function ArenaContextCustomization({ isDisabled }) {
  const { config, saveArena } = useArena();
  const [contextType, setContextType] = useState(
    config.configs.marketingTextA.general.context.value[0].type
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const textFieldProps = {
    text: {
      placeholder: "Enter custom context*",
      multiline: true,
      maxRows: 8,
    },
    website: {
      placeholder: "Enter website URL*",
    },
    s3: {
      placeholder: "NOT WORKING YET",
      disabled: true,
    },
  };

  return (
    <Stack>
      <Stack sx={{ gap: smallGap }}>
        <GreyOnHoverStack
          sx={{
            flexDirection: "row",
            gap: iconGap,
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          Context Customization{" "}
          <Icon
            name="ChevronUp"
            sx={{
              transition: defaultTransition,
              transform: isOpen ? "none" : "scaleY(-1)",
            }}
          />
        </GreyOnHoverStack>
        <Collapse in={isOpen}>
          <Stack
            sx={{
              gap: smallGap,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {isDisabled ||
            !!config.processStatuses?.marketingTextA?.result ||
            !!config.processStatuses?.marketingTextB?.result ? (
              <DisabledDropdown>
                {capitalizeFirstLetter(
                  replaceUnderscoreForWhitespace(
                    config.configs.marketingTextA.general.context.value[0].type
                  )
                )}
              </DisabledDropdown>
            ) : (
              <Dropdown
                initialSelected={contextType}
                options={config.configs.marketingTextA.general.context.type_options.map(
                  (o) => {
                    return {
                      label: capitalizeFirstLetter(
                        replaceUnderscoreForWhitespace(o)
                      ),
                      value: o,
                    };
                  }
                )}
                onChange={async (value) => {
                  setContextType(value);
                  const c = await saveArena({
                    "configs.marketingTextA.general.context.value[0].type":
                      value,
                    "configs.marketingTextB.general.context.value[0].type":
                      value,
                  });
                  setContextType(
                    c.configs.marketingTextA.general.context.value[0].type
                  );
                }}
              />
            )}

            <ArenaAutoUpdateTextField
              disabled={
                isDisabled ||
                !!config.processStatuses?.marketingTextA?.result ||
                !!config.processStatuses?.marketingTextB?.result
              }
              id="taskContext"
              sx={{
                width: "50%",
                minWidth: sidebarWidth,
              }}
              {...textFieldProps[contextType]}
              defaultValue={
                config.configs.marketingTextA.general.context.value[0].value ||
                ""
              }
              propPath={[
                "configs.marketingTextA.general.context.value[0].value",
                "configs.marketingTextB.general.context.value[0].value",
              ]}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
}
