import { Stack } from "@mui/material";
import SearchBar from "../../common/components/layout/SearchBar";
import { FullStack } from "../../common/styles/Box.styles";
import { defaultGap, smallerGap } from "../../styles/consts";
import ImageBoxOverview from "./ImageBoxOverview";
import InfoBoxOverview from "./InfoBox/InfoBoxOverview";
import ModuleDescriptionBox from "./ModuleDescriptionBox";
import RatingScoreOverview from "./RatingScoreOverview";
import ReviewsBoxOverview from "./ReviewsBoxOverview";
import TagsOverview from "./TagsOverview";
import ToolInDevelopmentBox from "./ToolInDevelopmentBox";

export default function OverviewPage({
  moduleOverview,
}: {
  moduleOverview: any;
}) {
  const { heading, status, tags, description, capabilities } = moduleOverview;
  return (
    <FullStack>
      <SearchBar type="" />
      <FullStack
        sx={{
          padding: `${smallerGap} ${defaultGap}`,
          boxSizing: "border-box",
          gap: defaultGap,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            gap: smallerGap,
            paddingBottom: smallerGap,
          }}
        >
          <TagsOverview tags={tags} />
          <RatingScoreOverview status={status} />
        </Stack>
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: defaultGap,
          }}
        >
          <ModuleDescriptionBox
            status={status}
            heading={heading}
            description={description}
          />
          {status === "inDevelopment" ? (
            <ToolInDevelopmentBox />
          ) : (
            <ImageBoxOverview />
          )}
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            gap: defaultGap,
            width: "100%",
          }}
        >
          <InfoBoxOverview capabilities={capabilities} />
          <ReviewsBoxOverview status={status} />
        </Stack>
      </FullStack>
    </FullStack>
  );
}
