import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { borderStyle, smallerGap } from "../../styles/consts";

export const SettingsArea = styled(Stack)(() => ({
  border: borderStyle,
  width: "22rem",
  minHeight: "28rem",
  flexDirection: "column" as any,
  justifyContent: "space-between",
}));

export const InternalSettingsAreaWithPadding = styled(Stack)(() => ({
  gap: smallerGap,
  padding: smallerGap,
}));

export const SettingsButtonSection = styled(Stack)(() => ({
  flexDirection: "row" as any,
  justifyContent: "right",
  borderTop: borderStyle,
}));
