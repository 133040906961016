import tokenService from "../../../api/TokenService";
import LoginPage from "../../../pages/Login/LoginPage";
import LayoutConfigProvider from "../../context/LayoutConfigProvider";
import PopUpProvider from "../../context/PopUpProvider";
import SnackbarProvider from "../../context/SnackbarProvider";
import LoggedInView from "../LoggedInView";

export default function Layout() {
  return (
    <PopUpProvider>
      <SnackbarProvider>
        <LayoutConfigProvider>
          {tokenService.isAccessTokenPresent() ? (
            <LoggedInView />
          ) : (
            <LoginPage />
          )}
        </LayoutConfigProvider>
      </SnackbarProvider>
    </PopUpProvider>
  );
}
