import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import {
  borderGrey,
  boxHeight,
  checkboxGap,
  defaultGap,
  smallGap,
} from "../../styles/consts";

export const StyledRow = styled(Stack)(() => ({
  width: "100%",
  flexDirection: "row" as "row",
  height: boxHeight,
  minHeight: boxHeight,
  maxHeight: boxHeight,
  padding: `${smallGap} 0 ${smallGap} ${defaultGap}`,
  borderBottom: borderGrey,
  boxSizing: "border-box" as any,
}));

export const StyledRowDataBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isLast" && prop !== "width",
})<{ isLast: boolean; width: string }>(({ isLast, width }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: checkboxGap,
  width: width,
  justifyContent: isLast ? "end" : "start",
  paddingRight: isLast ? "1.625rem" : "0",
}));
