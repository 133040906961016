import { Stack, Typography } from "@mui/material";
import Dropdown from "../../../../../common/components/fundamentals/Dropdown";
import NotImplemented from "../../../../../common/components/utils/NotImplemented";
import { PrimaryButton } from "../../../../../common/styles/Button.styles";
import { tinyGap } from "../../../../../styles/consts";

export default function ConfigSelect({ config }) {
  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: tinyGap,
      }}
    >
      <Stack
        sx={{
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Typography>Configuration</Typography>
      </Stack>

      <Dropdown
        sx={{
          whiteSpace: "nowrap",
        }}
        options={[{ value: config.id }]}
        initialSelected={config.id}
      />
      <NotImplemented popupLeft>
        <PrimaryButton>Generate default</PrimaryButton>
      </NotImplemented>
    </Stack>
  );
}
