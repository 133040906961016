import { Stack, Typography } from "@mui/material";
import Icon from "../../../../../../../common/components/fundamentals/Icon";
import { CustomSmallerCheckbox } from "../../../../../../../common/styles/Form.styles";
import { perelynPrimary, tinyGap } from "../../../../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../../../../utils";

export default function SocialMediaChoice({ name, isCurrentlySelected }) {
  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: tinyGap,
        }}
      >
        <CustomSmallerCheckbox>
          {isCurrentlySelected && (
            <Icon
              sx={{
                height: "100%",
                width: "100%",
              }}
              name="CheckboxX"
            />
          )}
        </CustomSmallerCheckbox>
        <Typography color={isCurrentlySelected ? perelynPrimary : ""}>
          {capitalizeFirstLetter(name)}
        </Typography>
      </Stack>
    </Stack>
  );
}
