import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "../../api/UserService";
import { BiggerIcon } from "../../common/components/fundamentals/Icon";
import MicrosoftButton from "../../common/components/fundamentals/MicrosoftButton";
import { useSnackbar } from "../../common/context/SnackbarProvider";
import { GreyOnHoverStack } from "../../common/styles/Box.styles";
import { PrimaryButton } from "../../common/styles/Button.styles";
import { SmallText, TitleText } from "../../common/styles/Text.styles";
import {
  defaultGap,
  defaultTransition,
  iconGap,
  perelynBlack,
  perelynPrimary,
  smallerGap,
} from "../../styles/consts";
import { flip } from "../../utils";

export default function LoginForm() {
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const [isVisible, setIsVisible] = useState(false);
  const [p, setP] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password")?.toString();
    if (!email || !password) {
      showSnackbar(
        "You need to enter your email and password to log in.",
        true
      );
      return;
    }
    try {
      await userService.login(email.toLowerCase(), password);
      window.location.reload();
      showSnackbar("Logged in successfully!");
    } catch (error) {
      showSnackbar("Invalid email or password. Please try again.", true, false);
      setIsLoading(false);
      if ((error as any).response.data.body === "User not confirmed") {
        await userService.resendVerificationCode(email);
        navigate("/verify-email", { state: { email: email } });
        showSnackbar(
          "User not verified. Confirm email in order to login",
          true,
          false
        );
      }
    }
  };

  const isLoadingHandler = () => {
    setIsLoading(true);
  };

  return (
    <Box component="form" onSubmit={submitLogin}>
      <Stack
        sx={{
          width: "22.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0.75rem",
            paddingBottom: "2rem",
          }}
        >
          <TitleText
            sx={{
              textAlign: "center",
              lineHeight: defaultGap,
            }}
          >
            Log in to your account
          </TitleText>
          <Typography>Welcome back! Please enter your details.</Typography>
        </Stack>
        <Stack sx={{ width: "100%", gap: "1.25rem" }}>
          <Stack>
            <SmallText>Email</SmallText>
            <TextField
              name="email"
              id="email"
              type="email"
              fullWidth
              placeholder="Enter your email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Stack>
          <Stack>
            <SmallText>Password</SmallText>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                name="password"
                id="password"
                type={isVisible ? "text" : "password"}
                fullWidth
                placeholder="●●●●●●●●●●"
                autoComplete="current-password"
                onChange={(e) => setP(e.target.value)}
              />
              {p && (
                <GreyOnHoverStack
                  sx={{
                    position: "fixed",
                    left: "calc(50% + 12rem)",
                  }}
                >
                  <BiggerIcon
                    sx={{
                      width: smallerGap,
                      height: smallerGap,
                      transition: defaultTransition,
                    }}
                    name={isVisible ? "Eye" : "EyeOff"}
                    onClick={flip(setIsVisible)}
                  />
                </GreyOnHoverStack>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%", padding: `${smallerGap} 0` }}>
          <GreyOnHoverStack onClick={() => navigate("/forgot-password")}>
            <SmallText sx={{ textDecoration: "underline" }}>
              Forgot password?
            </SmallText>
          </GreyOnHoverStack>
          <Stack sx={{ flexDirection: "row", gap: iconGap }}>
            <SmallText>Don't have an account? </SmallText>
            <GreyOnHoverStack onClick={() => navigate("/register")}>
              <SmallText sx={{ textDecoration: "underline" }}>
                Register now.
              </SmallText>
            </GreyOnHoverStack>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%", gap: "2rem" }}>
          <Stack sx={{ flexDirection: "row", gap: defaultGap }}>
            <PrimaryButton
              type="submit"
              sx={{
                fontSize: "1rem",
                justifyContent: "left",
                "&&": {
                  width: "5.5rem",
                  height: "2.75rem",
                },
                ":hover": {
                  "& .MuiCircularProgress-root": {
                    color: perelynPrimary,
                  },
                },
              }}
              onClick={isLoadingHandler}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{ color: perelynBlack }}
                  size={smallerGap}
                />
              ) : (
                "Sign in"
              )}
            </PrimaryButton>
          </Stack>

          <MicrosoftButton />
        </Stack>
      </Stack>
    </Box>
  );
}
