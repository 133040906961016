import { Stack } from "@mui/material";
import { FullStack } from "../../../../../common/styles/Box.styles";
import { borderStyle, smallerGap } from "../../../../../styles/consts";
import ResultSection from "../../../MarketingTextTool/components/result/ResultSection";
import ResultTypeHeading from "../../../MarketingTextTool/components/result/ResultTypeHeading";
import { useTask } from "../../../MarketingTextTool/context/TaskProvider";

export default function PdfDataExtractionResultsPage() {
  const { task, selectedParam } = useTask();

  return (
    <FullStack>
      <Stack
        sx={{
          gap: smallerGap,
        }}
      >
        <ResultTypeHeading type="Data set" />
        <Stack
          sx={{
            width: "100%",
            border: borderStyle,
          }}
        >
          <ResultSection
            type={"Data set"}
            label={selectedParam}
            basePropPath="processStatuses.entityExtraction.result.extracted_entities"
            results={
              Object.entries(
                task.processStatuses.entityExtraction.result
                  ?.extracted_entities || {}
              )
                .sort((a, b) => {
                  return a[0] < b[0] ? 1 : -1;
                })
                .map(([key, value]) => {
                  return {
                    label: key,
                    value: value,
                  };
                }) ?? []
            }
            isShort
            isLast
          />
        </Stack>
      </Stack>
    </FullStack>
  );
}
