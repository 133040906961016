
import { Arena, Task } from "../common/models/Models";
import { prepareResponse } from "../utils";
import apiAxios from "./axios";

const RUN_AGENT_ONE_URL = "/run-agent-one";
const RUN_AGENT_ONE_ARENA_URL = "/run-agent-one-arena";

class AgentOneService {

  async runMarketingTextGeneration(task: Task) {
    const response = await apiAxios.post<any>(
      RUN_AGENT_ONE_URL,
      {
        task,
        configs: ["marketingText", "marketingMetadata"]
      }
    );
    return prepareResponse(response);
  }

  async runMarketingTextArena(arena: Arena) {
    const response = await apiAxios.post<any>(
      RUN_AGENT_ONE_ARENA_URL,
      {
        arena,
        configs: ["marketingTextA", "marketingTextB"]
      }
    );
    return prepareResponse(response);
  }

  async runEntityExtraction(task: Task) {
    const response = await apiAxios.post<any>(
      RUN_AGENT_ONE_URL,
      {
        task,
        configs: ["entityExtraction"]
      }
    );
    return prepareResponse(response);
  }

  async getMarketingConfig() {
    const response = await apiAxios.get<any>(
      "/config/marketingText"
    );
    return prepareResponse(response);
  }
}

const agentOneService = new AgentOneService();

export default agentOneService;
