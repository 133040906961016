import { Stack, Typography } from "@mui/material";
import { DisabledDropdown } from "../../../../common/styles/Box.styles";
import { MediumText } from "../../../../common/styles/Text.styles";
import { defaultGap, tinyGap } from "../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../utils";
import ConfigBox from "../components/config/ConfigBox";
import DropdownWithLabel from "../components/config/section/SocialMediaSettings/DropdownWithLabel";
import { useArena } from "./ArenaProvider";
import ArenaDisabledPromptPresetSection from "./PromptSection/ArenaDisabledPromptPresetSection";
import ArenaPromptPresetSection from "./PromptSection/ArenaPromptPresetSection";

export default function ArenaConfigBox({ isDisabled }) {
  const { config, saveArena } = useArena();

  return (
    <Stack sx={{ height: "100%", width: "fit-content" }}>
      {isDisabled ||
      !!config.processStatuses?.marketingTextA?.result ||
      !!config.processStatuses?.marketingTextB?.result ? (
        <ConfigBox>
          <Stack sx={{ gap: tinyGap }}>
            <MediumText>Prompt preset settings</MediumText>
            <Stack
              sx={{
                flexDirection: "row",
                gap: defaultGap,
              }}
            >
              <ArenaDisabledPromptPresetSection
                model={"A"}
                configModel={"marketingTextA"}
              />
              <ArenaDisabledPromptPresetSection
                model={"B"}
                configModel={"marketingTextB"}
              />
            </Stack>
          </Stack>

          <Stack>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Language:</Typography>
              <DisabledDropdown>
                {capitalizeFirstLetter(
                  config.configs.marketingTextA.general.languages.value[0]
                )}
              </DisabledDropdown>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>Target group:</Typography>
              <DisabledDropdown>
                {capitalizeFirstLetter(
                  config.configs.marketingTextA.nodes.WriteLinkedinPostNode
                    .targetGroup.value
                )}
              </DisabledDropdown>
            </Stack>
          </Stack>
        </ConfigBox>
      ) : (
        <ConfigBox>
          <Stack sx={{ gap: tinyGap }}>
            <MediumText>Prompt preset settings</MediumText>
            <Stack
              sx={{
                flexDirection: "row",
                gap: defaultGap,
              }}
            >
              <ArenaPromptPresetSection
                model="A"
                configModel="marketingTextA"
                id="dropdownA"
              />
              <ArenaPromptPresetSection
                model="B"
                configModel="marketingTextB"
                id="dropdownB"
              />
            </Stack>
          </Stack>

          <Stack>
            <DropdownWithLabel
              zIndexModifier={100}
              label="Choose language"
              options={config.configs.marketingTextA.general.languages.options}
              value={config.configs.marketingTextA.general.languages.value[0]}
              onChange={(value) => {
                saveArena({
                  "configs.marketingTextA.general.languages.value": [value],
                  "configs.marketingTextB.general.languages.value": [value],
                });
              }}
            />
          </Stack>
          <Stack>
            <DropdownWithLabel
              label="Target group"
              options={
                config.configs.marketingTextA.nodes.WriteLinkedinPostNode
                  .targetGroup.options
              }
              value={
                config.configs.marketingTextA.nodes.WriteLinkedinPostNode
                  .targetGroup.value
              }
              onChange={(value) => {
                saveArena({
                  "configs.marketingTextA.nodes.WriteLinkedinPostNode.targetGroup.value":
                    value,
                  "configs.marketingTextB.nodes.WriteLinkedinPostNode.targetGroup.value":
                    value,
                });
              }}
            />
          </Stack>
        </ConfigBox>
      )}
    </Stack>
  );
}
