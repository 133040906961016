import { Stack } from "@mui/material";
import { HeadingText } from "../../common/styles/Text.styles";
import {
  defaultGap,
  iconSize,
  perelynMediumRed,
  smallerGap,
  smallGap,
} from "../../styles/consts";

export default function ChangelogPost({ children, date }) {
  return (
    <Stack sx={{ flexDirection: "row", gap: "10%" }}>
      <Stack
        sx={{
          width: "16rem",
          flexDirection: "row",
          gap: smallGap,
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              height: iconSize,
              width: iconSize,
              borderRadius: "50%",
              border: `4px solid ${perelynMediumRed}`,
            }}
          />
          <Stack
            sx={{
              height: "100%",
              width: "4px",
              backgroundColor: perelynMediumRed,
            }}
          />
        </Stack>
        <HeadingText sx={{ position: "relative", top: "-6px" }}>
          {date}
        </HeadingText>
      </Stack>
      <Stack sx={{ gap: smallerGap, paddingBottom: defaultGap }}>
        {children}
      </Stack>
    </Stack>
  );
}
