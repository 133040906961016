import { Stack, Typography } from "@mui/material";
import AutoUpdateNumberInput from "../../../../../../../common/components/fundamentals/AutoUpdateNumberInput";
import { perelynBlack, tinyGap } from "../../../../../../../styles/consts";
import { capitalizeFirstLetter } from "../../../../../../../utils";
import { useTask } from "../../../../context/TaskProvider";

export default function WordsLimit({
  id,
  type,
  color = perelynBlack,
}: {
  id: string;
  type: string;
  color?: string;
}) {
  const { task } = useTask();

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: tinyGap,
        boxSizing: "border-box",
      }}
    >
      <Typography sx={{ whiteSpace: "nowrap", color: color }}>
        {capitalizeFirstLetter(type)}. words
      </Typography>
      <AutoUpdateNumberInput
        id={id}
        defaultValue={
          task.configs.marketingText.nodes.WriteLinkedinPostNode.length[
            `${type}Words`
          ].value || 0
        }
        propPath={`configs.marketingText.nodes.WriteLinkedinPostNode.length.${type}Words.value`}
      />
    </Stack>
  );
}
