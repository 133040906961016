import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTask } from "../../../pages/Modules/MarketingTextTool/context/TaskProvider";
import {
  borderStyle,
  perelynPrimary,
  smallerGap,
  tinyGap,
} from "../../../styles/consts";
import { capitalizeFirstLetter, flip } from "../../../utils";
import { Task } from "../../models/Models";
import Checkbox from "./Checkbox";

export default function Choice({
  choice,
  isLast = false,
  checkIsCurrentlySelected,
  valueParamPath,
  valueList,
}: {
  choice: string;
  isLast: boolean;
  checkIsCurrentlySelected: (choice: string, t: Task) => boolean;
  valueParamPath: string;
  valueList: string[];
}) {
  const { task, saveTask } = useTask();
  const isCurrentlySelected = checkIsCurrentlySelected(choice, task);
  const [iC, setIC] = useState(isCurrentlySelected);
  const onCheckboxChange = async () => {
    let prevValue = iC;
    flip(setIC)();
    const value = prevValue
      ? valueList.filter((v) => v !== choice)
      : [...valueList, choice];
    const updatedTask = await saveTask({
      [valueParamPath]: value,
    });
    setIC(checkIsCurrentlySelected(choice, updatedTask));
  };
  return (
    <Stack
      sx={{
        paddingBottom: isLast ? 0 : smallerGap,
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: tinyGap,
          paddingBottom: "0.75rem",
          boxSizing: "border-box",
        }}
      >
        <Checkbox
          iconName="CheckboxX"
          sx={{
            border: borderStyle,
          }}
          isChecked={iC}
          onChange={onCheckboxChange}
        />

        <Typography color={iC ? perelynPrimary : ""}>
          {capitalizeFirstLetter(choice)}
        </Typography>
      </Stack>
    </Stack>
  );
}
