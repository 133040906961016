import React, { createContext, useContext, useState } from "react";
import CustomSnackbar from "../components/utils/CustomSnackbar";

type SnackbarState = {
  showSnackbar: (
    message: string,
    isError?: boolean,
    isAutoClose?: boolean
  ) => void;
};

const SnackbarContext = createContext<SnackbarState>({
  showSnackbar: () => {},
});

export const useSnackbar = (): SnackbarState => {
  const snackbarState = useContext(SnackbarContext);
  if (!snackbarState) {
    throw new Error("useLoading must be used within a LayoutConfigProvider");
  }
  return snackbarState;
};

export default function SnackbarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isAutoClose, setIsAutoClose] = useState(true);

  const showSnackbar = (message: string, error = false, autoClose = true) => {
    setMessage(message);
    setIsError(error);
    setOpen(true);
    setIsAutoClose(autoClose);
  };

  const snackbarState: SnackbarState = {
    showSnackbar: showSnackbar,
  };

  return (
    <SnackbarContext.Provider value={snackbarState}>
      <>{children}</>
      <CustomSnackbar
        error={isError}
        open={open}
        message={message}
        setOpen={setOpen}
        autoClose={isAutoClose}
      />
    </SnackbarContext.Provider>
  );
}
