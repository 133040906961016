import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import agentOneService from "../../../../../api/AgentOneService";
import Choice from "../../../../../common/components/fundamentals/Choice";
import Dropdown from "../../../../../common/components/fundamentals/Dropdown";
import { FullStack } from "../../../../../common/styles/Box.styles";
import { OrangeButton } from "../../../../../common/styles/Button.styles";
import {
  HeadingText,
  SmallText,
} from "../../../../../common/styles/Text.styles";
import { dashboardPath, PDF_DATA_EXTRACTION } from "../../../../../routes";
import {
  borderStyle,
  defaultGap,
  iconSize,
  smallerGap,
  tinyGap,
} from "../../../../../styles/consts";
import { useTask } from "../../../MarketingTextTool/context/TaskProvider";
import UploadBox from "./UploadBox";

export default function PdfDataExtractionConfigPage() {
  const { task } = useTask();
  const navigate = useNavigate();
  const handleGenerate = async () => {
    await agentOneService.runEntityExtraction(task);
    navigate(dashboardPath(PDF_DATA_EXTRACTION));
  };
  return (
    <FullStack>
      <Stack
        sx={{
          paddingBottom: defaultGap,
        }}
      >
        <HeadingText>Let's get started</HeadingText>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          gap: "3rem",
          paddingBottom: defaultGap,
          boxSizing: "border-box",
        }}
      >
        <Stack
          sx={{
            gap: iconSize,
            width: "12rem",
          }}
        >
          <HeadingText>Add file/s*</HeadingText>
          <SmallText>
            Drag and drop a file to the box on the right or click on it to
            upload a file.
          </SmallText>
        </Stack>

        <UploadBox
          value={task.configs.entityExtraction.general.inputSource.value}
          propertyPath="configs.entityExtraction.general.inputSource.value"
          module="entityExtraction"
        />
      </Stack>
      <Stack
        sx={{
          height: "fit-content",
          border: borderStyle,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            padding: smallerGap,
          }}
        >
          <HeadingText>Select parameters</HeadingText>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: tinyGap }}
          >
            <Typography>Configuration</Typography>
            <Dropdown
              sx={{
                whiteSpace: "nowrap",
              }}
              options={[{ value: task.configs.entityExtraction.id }]}
              initialSelected={task.configs.entityExtraction.id}
            />
          </Stack>
        </Stack>
        <Stack sx={{ padding: smallerGap }}>
          {task.configs.entityExtraction.nodes.EntityExtractionNode.entities.options.map(
            (option, idx) => (
              <Choice
                choice={option}
                key={idx}
                isLast={false}
                valueList={
                  task.configs.entityExtraction.nodes.EntityExtractionNode
                    .entities.value
                }
                checkIsCurrentlySelected={(choice, t) =>
                  t.configs.entityExtraction.nodes.EntityExtractionNode.entities.value.includes(
                    choice
                  )
                }
                valueParamPath="configs.entityExtraction.nodes.EntityExtractionNode.entities.value"
              />
            )
          )}
        </Stack>
        <Stack
          sx={{
            borderTop: borderStyle,
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <OrangeButton
            id="continueProcess"
            onClick={handleGenerate}
            sx={{ borderLeft: borderStyle }}
          >
            Generate
          </OrangeButton>
        </Stack>
      </Stack>
    </FullStack>
  );
}
