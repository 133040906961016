import { useState } from "react";
import CircularLoader from "../components/fundamentals/Loader/CircularLoader";

export default function useCircularLoader() {
  const [loading, setLoading] = useState(true);
  return {
    loading,
    setLoading,
    LoaderComponent: CircularLoader,
  };
}
