import { Stack, TextField } from "@mui/material";
import { useState } from "react";
import { BiggerIcon } from "../../../common/components/fundamentals/Icon";
import { GreyOnHoverStack } from "../../../common/styles/Box.styles";
import {
  defaultTransition,
  smallGap,
  smallerGap,
} from "../../../styles/consts";
import { flip } from "../../../utils";

export default function ForgotPasswordField({
  id,
  name,
  placeholder,
  value,
  setValue,
  onChange,
}) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: smallGap,
        width: "100%",
        alignItems: "center",
      }}
    >
      <TextField
        fullWidth
        placeholder={placeholder}
        name={name}
        id={id}
        type={isVisible ? "text" : "password"}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
      />
      {value !== "" && (
        <GreyOnHoverStack
          sx={{
            position: "fixed",
            left: "calc(50% + 12rem)",
          }}
        >
          <BiggerIcon
            sx={{
              width: smallerGap,
              height: smallerGap,
              transition: defaultTransition,
            }}
            name={isVisible ? "Eye" : "EyeOff"}
            onClick={flip(setIsVisible)}
          />
        </GreyOnHoverStack>
      )}
    </Stack>
  );
}
