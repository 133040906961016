import { Collapse, Stack } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import arenaService from "../../../api/ArenaService";
import taskService from "../../../api/TaskService";
import { basePath, dashboardPath } from "../../../routes";
import {
  borderStyle,
  boxHeight,
  defaultGap,
  defaultTransition,
  iconGap,
  iconSize,
  sidebarWidth,
  smallerGap,
  tinyGap,
} from "../../../styles/consts";
import { flip } from "../../../utils";
import { useLayoutConfig } from "../../context/LayoutConfigProvider";
import { useLoading } from "../../context/LoadingProvider";
import { GreyOnHoverStack } from "../../styles/Box.styles";
import {
  OrangeBigButton,
  TransparentBigButton,
} from "../../styles/Button.styles";
import {
  MainSidebarComponent,
  MiddleSidebarComponent,
} from "../../styles/Sidebar.styles";
import { HeadingText } from "../../styles/Text.styles";
import Icon, { BiggerIcon } from "../fundamentals/Icon";

export default function SecondarySidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { withLoading } = useLoading();
  const { moduleName } = useLayoutConfig();
  const [isFolded, setIsFolded] = useState(true);

  const createNewTask = async () => {
    withLoading(async () => {
      const task = await taskService.createTask(moduleName);
      navigate(`${basePath(moduleName)}/${task.createdTimestamp}`);
    });
  };

  const handleBackToToolHistory = () => {
    navigate(dashboardPath(moduleName));
  };

  const handleArenaClick = () => {
    withLoading(async () => {
      const arena = await arenaService.createArena(moduleName);
      navigate(`${basePath(moduleName)}/arena/${arena.createdTimestamp}`);
    });
  };

  const handleFold = () => {
    flip(setIsFolded)();
  };

  return (
    <Collapse
      in={!isFolded}
      orientation="horizontal"
      collapsedSize={boxHeight}
      sx={{
        // "&&": {
        //   width: isFolded ? boxHeight : sidebarWidth,
        //   maxWidth: isFolded ? boxHeight : sidebarWidth,
        //   minWidth: isFolded ? boxHeight : sidebarWidth,
        // },
        width: isFolded
          ? `${boxHeight} !important`
          : `${sidebarWidth} !important`,
        maxWidth: isFolded
          ? `${boxHeight} !important`
          : `${sidebarWidth} !important`,
        minWidth: isFolded
          ? `${boxHeight} !important`
          : `${sidebarWidth} !important`,
        borderRight: borderStyle,
        transition: defaultTransition,
      }}
    >
      <MainSidebarComponent>
        <Stack>
          <OrangeBigButton
            sx={{
              borderBottom: borderStyle,
              gap: iconGap,
              paddingRight: isFolded ? "11rem " : "0",
            }}
            onClick={createNewTask}
          >
            <BiggerIcon
              sx={{
                width: defaultGap,
                height: defaultGap,
              }}
              name="Plus"
            />
            {!isFolded && "Create new"}
          </OrangeBigButton>

          <TransparentBigButton
            sx={{
              borderBottom: borderStyle,
              gap: iconGap,
              paddingRight: isFolded ? "11rem " : "0",
            }}
            onClick={handleFold}
          >
            <BiggerIcon
              sx={{
                width: defaultGap,
                height: defaultGap,
                transform: isFolded ? "scaleX(-1)" : "none",
                transition: defaultTransition,
              }}
              name="DoubleChevron"
            />
          </TransparentBigButton>
        </Stack>

        <MiddleSidebarComponent
          sx={{
            transition: defaultTransition,
            "&&": {
              padding: isFolded
                ? "0rem 11rem 1rem 1.5rem"
                : `${defaultGap} ${smallerGap} ${defaultGap} ${defaultGap}`,
              alignItems: "left",
            },
          }}
        >
          <Stack
            sx={{
              gap: smallerGap,
              display: isFolded ? "none" : "flex",
              textWrap: "nowrap",
            }}
          >
            <HeadingText>Tool History</HeadingText>
            <Stack gap={tinyGap}>
              <Stack gap={tinyGap}>
                <Stack flexDirection="row" alignItems="center" gap={iconGap}>
                  <GreyOnHoverStack>
                    <Icon name="Folder" />
                    Folder open
                    <Icon name="ChevronUp" />
                    <Icon name="DragComponent" />
                  </GreyOnHoverStack>
                </Stack>

                {/* <Stack flexDirection="row" alignItems="center" gap={iconGap}>
                  <Icon name="RedFile" />
                  <OrangeSidebarStack>Task 1</OrangeSidebarStack>
                </Stack> */}
              </Stack>
              <Stack>Favorite Tools</Stack>
              <Stack>Submit Idea</Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: iconGap,
              marginTop: isFolded ? "auto" : "0",
              textWrap: "nowrap",
            }}
          >
            <Icon
              sx={{
                transition: defaultTransition,
                width: isFolded ? "1.75rem" : iconSize,
                height: isFolded ? "1.75rem" : iconSize,
              }}
              name="Settings"
            />
            {!isFolded && "Tool Settings"}
          </Stack>
        </MiddleSidebarComponent>

        <Stack>
          {location.pathname === "/marketing-text/dashboard" && (
            <TransparentBigButton
              id="arena"
              sx={{
                borderTop: borderStyle,
                paddingRight: isFolded ? "11rem " : "0",
              }}
              onClick={handleArenaClick}
            >
              {isFolded ? (
                <BiggerIcon
                  sx={{
                    width: "1.75rem",
                    height: "1.75rem",
                  }}
                  name="Stars"
                />
              ) : (
                "Compare prompts"
              )}
            </TransparentBigButton>
          )}

          <TransparentBigButton
            id="back-to-tool-history"
            sx={{
              borderTop: borderStyle,
              paddingRight: isFolded ? "11rem " : "0",
            }}
            onClick={handleBackToToolHistory}
          >
            {isFolded ? (
              <BiggerIcon
                sx={{
                  width: defaultGap,
                  height: defaultGap,
                  minHeight: defaultGap,
                  maxHeight: defaultGap,
                }}
                name="Return"
              />
            ) : (
              "Back to tool history overview"
            )}
          </TransparentBigButton>
        </Stack>
      </MainSidebarComponent>
    </Collapse>
  );
}
