import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import userService from "../../../api/UserService";
import { useSnackbar } from "../../../common/context/SnackbarProvider";
import { useUser } from "../../../common/context/UserProvider";
import { OrangeButton } from "../../../common/styles/Button.styles";
import {
  borderStyle,
  perelynBlack,
  perelynPrimary,
  smallerGap,
} from "../../../styles/consts";
import { flip } from "../../../utils";
import { InternalSettingsAreaWithPadding } from "../Settings.styles";

export default function ModifyPromptPopup({
  setIsOpen,
  promptName,
  promptContent,
  isArena,
}: {
  setIsOpen: (isOpen: boolean) => void;
  promptName?: string;
  promptContent?: string;
  isArena?: any;
}) {
  const { showSnackbar } = useSnackbar();
  const { user, setUser } = useUser();
  const [isSaving, setIsSaving] = useState(false);

  const savePrompt = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPromptName = data.get("prompt-name")?.toString();
    const newContent = data.get("prompt-content")?.toString();
    if (!newPromptName || !newContent) {
      showSnackbar("Please fill in all fields", true);
      flip(setIsSaving)();
      return;
    }

    if (user.prompts && user.prompts.hasOwnProperty(newPromptName)) {
      showSnackbar(
        "Prompt with this name already exists, please pick a diffrent one.",
        true
      );
      flip(setIsSaving)();
      return;
    }

    try {
      const additionalParams = {};
      if (promptName && promptName !== newPromptName) {
        additionalParams["previousPromptName"] = promptName;
      }

      await userService.updateUserPrompt(
        {
          [`prompts.${newPromptName}`]: newContent,
          ...additionalParams,
        },
        setUser
      );

      showSnackbar("Prompt saved successfully", false);
      flip(setIsOpen)();
    } catch (error) {
      showSnackbar("An error occurred", true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      component="form"
      onSubmit={savePrompt}
    >
      <InternalSettingsAreaWithPadding
        sx={{ height: "100%", border: borderStyle }}
      >
        <Stack>
          <Typography>Name</Typography>
          <TextField
            id="prompt-name"
            name="prompt-name"
            placeholder="Enter prompt name*"
            defaultValue={promptName}
          />
        </Stack>
        <Stack>
          {/* <Typography>Prompt content</Typography> */}
          <TextField
            id="prompt-content"
            name="prompt-content"
            multiline
            rows="10"
            placeholder="Enter prompt*"
            defaultValue={isArena ? isArena : promptContent}
          />
        </Stack>
      </InternalSettingsAreaWithPadding>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "right",
          border: borderStyle,
          borderTop: "none",
        }}
      >
        <OrangeButton
          onClick={flip(setIsSaving)}
          type="submit"
          sx={{
            borderLeft: borderStyle,
            width: "4rem",
            ":hover": {
              "& .MuiCircularProgress-root": {
                color: perelynPrimary,
              },
            },
          }}
        >
          {isSaving ? (
            <CircularProgress sx={{ color: perelynBlack }} size={smallerGap} />
          ) : (
            "Save"
          )}
        </OrangeButton>
      </Stack>
    </Box>
  );
}
