import { Stack } from "@mui/material";
import userService from "../../../api/UserService";
import { BiggerIcon } from "../../../common/components/fundamentals/Icon";
import { usePopUp } from "../../../common/context/PopUpProvider";
import { useSnackbar } from "../../../common/context/SnackbarProvider";
import { useUser } from "../../../common/context/UserProvider";
import { OrangeOnHoverStack } from "../../../common/styles/Box.styles";
import {
  HeadingText,
  SmallText,
  UnderlinedOnHoverText,
} from "../../../common/styles/Text.styles";
import {
  iconGap,
  perelynGrey,
  smallerGap,
  smallGap,
  tinyGap,
} from "../../../styles/consts";
import PopUpBox from "../../Modules/MarketingTextTool/components/result/PopUpBox";
import { InternalSettingsAreaWithPadding } from "../Settings.styles";
import ModifyPromptPopup from "./ModifyPromptPopup";

export default function PromptsList() {
  const { showPopUpWindow } = usePopUp();
  const { showSnackbar } = useSnackbar();
  const { user, setUser } = useUser();

  const handleDeleteClick = (promptName: string) => {
    showPopUpWindow(
      () => handlePromptDelete(promptName),
      `Delete prompt ${promptName}?`,
      `Are you sure you want to delete this prompt? This action cannot be reversed.`,
      "Cancel",
      "Delete"
    );
  };

  const handlePromptDelete = (promptName: string) => {
    userService.deleteUserPrompt(promptName, setUser);
    showSnackbar("Prompt successfully deleted!", false);
  };

  const promptsArray = Object.entries(user.prompts || {});

  return (
    <InternalSettingsAreaWithPadding sx={{ height: "100%" }}>
      <Stack sx={{ gap: smallGap, height: "16rem" }}>
        <Stack>
          <HeadingText>Your prompt presets</HeadingText>
          <SmallText color={perelynGrey}>
            {!user.prompts
              ? "To create new prompt, click on Add new button"
              : "In order to edit prompt, click on its name"}
          </SmallText>
        </Stack>

        <Stack
          sx={{
            gap: tinyGap,
            paddingRight: smallerGap,
            height: "100%",
            overflowY: "auto",
            flex: "1",
          }}
        >
          {promptsArray.map(([promptName, promptContent]) => (
            <Stack
              key={promptName}
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: iconGap,
                justifyContent: "space-between",
              }}
            >
              <PopUpBox
                button={
                  <UnderlinedOnHoverText>{promptName}</UnderlinedOnHoverText>
                }
                heading={"Edit prompt"}
              >
                {(setIsOpen) => (
                  <ModifyPromptPopup
                    promptName={promptName}
                    promptContent={promptContent}
                    setIsOpen={setIsOpen}
                  />
                )}
              </PopUpBox>
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: tinyGap,
                }}
              >
                <OrangeOnHoverStack
                  onClick={() => handleDeleteClick(promptName)}
                >
                  <BiggerIcon name="Trash" />
                </OrangeOnHoverStack>
                <PopUpBox
                  button={
                    <OrangeOnHoverStack>
                      <BiggerIcon name="Edit" />
                    </OrangeOnHoverStack>
                  }
                  heading={"Edit prompt"}
                >
                  {(setIsOpen) => (
                    <ModifyPromptPopup
                      promptName={promptName}
                      promptContent={promptContent}
                      setIsOpen={setIsOpen}
                    />
                  )}
                </PopUpBox>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </InternalSettingsAreaWithPadding>
  );
}
