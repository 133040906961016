import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { borderStyle, boxHeight, defaultGap } from "../../../styles/consts";
import { getItemsFromIndex, getNestedProperty } from "../../../utils";
import { Option } from "../fundamentals/Dropdown";
import FilteringArea from "./FilteringArea";
import LabelRow from "./LabelRow";
import PageNavigation from "./PageNavigation";
import Row, { ItemRow } from "./Row";

export type Sort = {
  param: string;
  order: "asc" | "desc";
};

export default function Table<T>({
  items,
  filterFunction,
  getItemRow,
  filters,
  idProperty,
  deleteAllFunction,
  defaultSort,
  deletable = true,
}: {
  items: T[];
  filterFunction: (value: string) => T[];
  getItemRow: (item: T) => ItemRow[];
  filters: Option[];
  idProperty: string;
  deleteAllFunction?: (ids: string[]) => void;
  defaultSort?: Sort;
  deletable?: boolean;
}) {
  const [page, setPage] = useState(1);
  const visibleRowsNo = 6;
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const [currentlyDisplayedItems, setCurrentlyDisplayedItems] = useState<T[]>(
    []
  );
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
  const [currentSort, setCurrentSort] = useState<Sort>(
    defaultSort || {
      param: idProperty,
      order: "desc",
    }
  );
  const labels = getItemRow({} as T);
  const handleCheckboxClick = (itemId: string) => {
    setSelectedTaskIds((prev) => {
      if (selectedTaskIds.includes(itemId)) {
        return prev.filter((id) => id !== itemId);
      } else {
        return [...prev, itemId];
      }
    });
  };

  const changeSort = (param?: string) => {
    if (!param) return;

    setCurrentSort((prev) => {
      if (prev.param === param) {
        return {
          param,
          order: prev.order === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          param,
          order: "desc",
        };
      }
    });
  };

  useEffect(() => {
    const getSortedItems = (items: T[]) => {
      return items.sort((a, b) => {
        const aVal = getNestedProperty(a, currentSort.param);
        const bVal = getNestedProperty(b, currentSort.param);

        if (aVal === undefined && bVal !== undefined) {
          return currentSort.order === "asc" ? -1 : 1;
        } else if (aVal !== undefined && bVal === undefined) {
          return currentSort.order === "asc" ? 1 : -1;
        } else if (aVal === undefined && bVal === undefined) {
          return 0;
        }

        if (currentSort.order === "asc") {
          return aVal > bVal ? 1 : -1;
        } else {
          return aVal < bVal ? 1 : -1;
        }
      });
    };

    setCurrentlyDisplayedItems(
      getItemsFromIndex(
        getSortedItems(filteredItems),
        (page - 1) * visibleRowsNo,
        visibleRowsNo
      )
    );
  }, [page, filteredItems, currentSort]);

  const handleAllDisplayedSelect = () => {
    if (selectedTaskIds.length > 0) {
      setSelectedTaskIds([]);
    } else {
      setSelectedTaskIds(
        currentlyDisplayedItems.map((item) => item[idProperty])
      );
    }
  };

  return (
    <Stack>
      <Stack
        sx={{
          padding: `${defaultGap} 0 0.75rem ${defaultGap}`,
          borderBottom: borderStyle,
          boxSizing: "border-box",
        }}
      >
        <FilteringArea
          deletable={deletable}
          deleteAllFunction={() => {
            if (deleteAllFunction) {
              deleteAllFunction(selectedTaskIds);
            }
          }}
          filterFunction={filterFunction}
          setFilteredItems={setFilteredItems}
          setPage={setPage}
          filters={filters}
          anySelected={selectedTaskIds.length > 0}
          items={items}
        />
        <LabelRow
          labels={labels}
          isChecked={selectedTaskIds.length > 0}
          handleAllDisplayedSelect={handleAllDisplayedSelect}
          currentSort={currentSort}
          changeSort={changeSort}
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          minHeight: `calc(${boxHeight} * ${visibleRowsNo})`,
        }}
      >
        {currentlyDisplayedItems.map((item, idx) => (
          <Row
            key={idx}
            itemRow={getItemRow(item)}
            handleCheckboxClick={() => handleCheckboxClick(item[idProperty])}
            isChecked={selectedTaskIds.includes(item[idProperty])}
          />
        ))}
      </Stack>
      <PageNavigation
        page={page}
        setPage={setPage}
        filteredItems={filteredItems}
        visibleRowsNo={visibleRowsNo}
      />
    </Stack>
  );
}
