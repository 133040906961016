import { Arena, PartialArenaParams } from "../common/models/Models";
import { prepareResponse } from "../utils";
import apiAxios from "./axios";

const ARENA_URL = "/arena";


const getURLWithCreatedTimestamp = (url: string, createdTimestamp: string) =>
  `${url}/${createdTimestamp}`;

class ArenaService {
  async createArena(type: string): Promise<Arena> {
    return prepareResponse(await apiAxios.post<Arena>(ARENA_URL, { type }));
  }

  async getArena(createdTimestamp: string): Promise<Arena> {
    return prepareResponse(
      await apiAxios.get<Arena>(
        getURLWithCreatedTimestamp(ARENA_URL, createdTimestamp)
      )
    );
  }

  async updateArena(
    createdTimestamp: string,
    arenaPropertiesToUpdate: PartialArenaParams
  ): Promise<Arena> {
    return prepareResponse(
      await apiAxios.put<Arena>(
        getURLWithCreatedTimestamp(ARENA_URL, createdTimestamp),
        arenaPropertiesToUpdate
      )
    );
  }
}

const arenaService = new ArenaService();
export default arenaService;
