import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon, { BiggerIcon } from "../../../common/components/fundamentals/Icon";
import Image from "../../../common/components/fundamentals/Image";
import { useSnackbar } from "../../../common/context/SnackbarProvider";
import { useUser } from "../../../common/context/UserProvider";
import { OrangeButton } from "../../../common/styles/Button.styles";
import { HeadingText, SmallText } from "../../../common/styles/Text.styles";
import {
  borderStyle,
  checkboxGap,
  defaultGap,
  iconGap,
  perelynBlack,
  perelynPrimary,
  smallGap,
  tinyGap,
} from "../../../styles/consts";

export default function ModuleBox({ module }) {
  const navigate = useNavigate();
  const { user } = useUser();
  const { showSnackbar } = useSnackbar();
  const { id, heading, description, tags, iconName, path, disabled, euAiAct } =
    module;
  const handleMoreClick = () => {
    navigate(path);
  };

  const handleUseClick = () => {
    if (user.active === true) {
      navigate(path + "/dashboard");
    } else {
      showSnackbar(
        "Your account needs to be activated by administrator in order to access Assistants.",
        true,
        false
      );
    }
  };

  return (
    <Stack
      sx={{
        height: "16rem",
        width: "23rem",
        border: borderStyle,
        justifyContent: "space-between",
      }}
    >
      <Stack>
        <Stack
          sx={{
            height: defaultGap,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack padding={"0.5rem"}>
            <Image src="YLogo" />
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
            }}
          >
            {euAiAct && (
              <Stack
                sx={{
                  height: defaultGap,
                  width: defaultGap,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                  name="EuAiAct"
                />
              </Stack>
            )}

            <Stack
              sx={{
                height: defaultGap,
                width: defaultGap,
                borderLeft: borderStyle,
                borderBottom: borderStyle,
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <BiggerIcon name={iconName} />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            height: "10.5rem",
          }}
        >
          <Stack
            sx={{
              padding: checkboxGap,
              boxSizing: "border-box",
            }}
          >
            <HeadingText>{heading}</HeadingText>
            <Typography>{description}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          height: defaultGap,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: iconGap,
          boxSizing: "border-box",
          gap: "0.5rem",
          borderTop: borderStyle,
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: iconGap }}>
          {tags.slice(0, 3).map((tag, idx) => (
            <SmallText key={idx}>#{tag}</SmallText>
          ))}
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          {iconName === "GreenCheckSquare" && (
            <Button
              id={id}
              sx={{
                borderLeft: borderStyle,
                ":hover": {
                  backgroundColor: perelynBlack,
                  color: perelynPrimary,
                },
              }}
              onClick={handleUseClick}
            >
              Use
            </Button>
          )}

          <OrangeButton
            id={id + "-overview"}
            disabled={disabled}
            sx={{
              borderLeft: borderStyle,
              borderBottom: borderStyle,
              padding: `${tinyGap} ${smallGap}`,
            }}
            onClick={handleMoreClick}
          >
            More
          </OrangeButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
