import { Stack } from "@mui/material";
import { Children } from "react";
import { borderOrange, smallerGap } from "../../../../../styles/consts";

export default function ConfigBox({ children }) {
  const length = Children.count(children);
  return (
    <Stack
      sx={{
        width: "100%",
        boxSizing: "border-box",
        height: "fit-content",
        border: borderOrange,
      }}
    >
      {Children.map(children, (child, idx) => {
        return (
          <Stack
            sx={{
              borderBottom: idx < length - 1 ? borderOrange : "none",
              padding: smallerGap,
              boxSizing: "border-box",
            }}
          >
            {child}
          </Stack>
        );
      })}
    </Stack>
  );
}
