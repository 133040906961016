import { Box, SxProps } from "@mui/material";

export default function Image({
  src,
  sx,
  onClick,
  name,
  type = "svg",
}: {
  src: string;
  sx?: SxProps;
  onClick?: () => void;
  name?: string;
  type?: string;
}) {
  return (
    <Box
      id={name}
      component="img"
      onClick={onClick}
      src={`/${type}/${src}${src.includes(".") ? "" : ".svg"}`}
      sx={sx}
    />
  );
}
