import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { defaultGap, sidebarWidth, smallerGap } from "../../styles/consts";

export const MainSidebarComponent = styled(Stack)(() => ({
  height: "100%",
  width: sidebarWidth,
  minWidth: sidebarWidth,
  maxWidth: sidebarWidth,
  justifyContent: "space-between",
}));

export const MiddleSidebarComponent = styled(Stack)(
  () =>
    ({
      flexGrow: "1",
      padding: `${defaultGap} ${smallerGap} ${defaultGap} ${defaultGap}`,
      justifyContent: "space-between",
      overflow: "auto",
    } as any)
);
