import { Stack } from "@mui/material";
import Icon from "../../../../../common/components/fundamentals/Icon";
import { SmallText } from "../../../../../common/styles/Text.styles";
import { tagBackgroundColor } from "../../../../../styles/consts";

export default function PromptSettingsTag({ type }) {
  return (
    <Stack
      sx={{
        borderRadius: "6.1875rem",
        backgroundColor: tagBackgroundColor,
        padding: "0.19rem 0.25rem 0.19rem 0.5rem",
        gap: "0.1875rem",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <SmallText>{type}</SmallText>
      <Icon
        sx={{
          height: "0.625rem",
          width: "0.625rem",
          padding: "0.125rem",
        }}
        name="XClose"
      />
    </Stack>
  );
}
