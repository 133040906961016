import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import organizationService from "../../../api/OrganizationService";
import { usePopUp } from "../../../common/context/PopUpProvider";
import { useUser } from "../../../common/context/UserProvider";
import useCircularLoader from "../../../common/hooks/useCircularLoader";
import { Organization, User } from "../../../common/models/Models";
import { OutlinedButton } from "../../../common/styles/Button.styles";
import { MediumText } from "../../../common/styles/Text.styles";
import { smallGap } from "../../../styles/consts";
import {
  InternalSettingsAreaWithPadding,
  SettingsButtonSection,
} from "../Settings.styles";

export default function OrganizationOverview() {
  const { user, reloadUser } = useUser();
  const [organization, setOrganization] = useState<Organization>();
  const { LoaderComponent, loading, setLoading } = useCircularLoader();
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);
  const { showPopUpWindow } = usePopUp();
  useEffect(() => {
    if (user && user.organization) {
      const fetchOrganization = async () => {
        if (user.organization) {
          const o = await organizationService.getOrganization(
            user.organization
          );
          const ou = await organizationService.getOrganizationUsers(
            user.organization
          );
          setOrganization(o);
          setOrganizationUsers(ou);
          setLoading(false);
        }
      };
      fetchOrganization();
    }
  }, [user, setLoading]);

  const deleteOrganization = async () => {
    if (organization) {
      await organizationService.deleteOrganization(organization.name);
      reloadUser();
    }
  };

  const handleDeleteOrganizationClick = async () => {
    showPopUpWindow(
      () => deleteOrganization(),
      `Delete organization ${organization?.name}`,
      `Are you sure you want to delete organization with this name: ${organization?.name}?`,
      "Cancel",
      "Delete"
    );
  };

  return loading || !organization || !organizationUsers ? (
    <LoaderComponent />
  ) : (
    <Stack
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <InternalSettingsAreaWithPadding>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          {organization?.name}
        </Typography>
        <Stack
          sx={{
            gap: smallGap,
          }}
        >
          <Stack>
            <MediumText>Admin</MediumText>
            <Typography>
              {
                organizationUsers.find((u) => u.id === organization.owner)
                  ?.email
              }
            </Typography>
          </Stack>
          <Stack>
            <MediumText>Members</MediumText>

            {organizationUsers.filter((user) => {
              const userFromOrg = organization.users.find(
                (u) => u.id === user.id
              );
              return userFromOrg?.role === "MEMBER";
            }).length === 0 ? (
              <Typography>None</Typography>
            ) : (
              organizationUsers
                .filter((user) => {
                  const userFromOrg = organization.users.find(
                    (u) => u.id === user.id
                  );
                  return userFromOrg?.role === "MEMBER";
                })
                .map((user) => {
                  const userFromOrg = organization.users.find(
                    (u) => u.id === user.id
                  );
                  return (
                    <Typography key={user.id}>
                      {user.email} -{" "}
                      {userFromOrg?.active ? "Active" : "Inactive"}
                    </Typography>
                  );
                })
            )}
          </Stack>
        </Stack>
      </InternalSettingsAreaWithPadding>
      <SettingsButtonSection>
        {organization.owner === user.id ? (
          <OutlinedButton
            sx={{
              "&&": {
                borderBottom: "none",
                borderTop: "none",
                borderRight: "none",
              },
            }}
            onClick={handleDeleteOrganizationClick}
          >
            Delete organization
          </OutlinedButton>
        ) : (
          <OutlinedButton
            sx={{
              "&&": {
                borderBottom: "none",
                borderTop: "none",
                borderRight: "none",
              },
            }}
            onClick={handleDeleteOrganizationClick}
          >
            Quit
          </OutlinedButton>
        )}
      </SettingsButtonSection>
    </Stack>
  );
}
