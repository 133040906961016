import { useLocation } from "react-router-dom";
import userService from "../../../api/UserService";
import DataLoadingWrapper from "../../../common/components/utils/DataLoadingWrapper";
import AdminSettings from "./AdminSettings";
import UsersProvider from "./UsersProvider";

export default function ArenaWrapper() {
  const location = useLocation();

  return (
    <DataLoadingWrapper
      ContextProvider={UsersProvider}
      fetchFunctions={[userService.getUsers]}
      observeParam={location.pathname}
    >
      <AdminSettings />
    </DataLoadingWrapper>
  );
}
