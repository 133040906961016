import { useNavigate } from "react-router-dom";
import tokenService from "../../../api/TokenService";
import { borderStyle, defaultGap } from "../../../styles/consts";
import { TransparentSmallButton } from "../../styles/Button.styles";
import Icon from "../fundamentals/Icon";

export default function Logout() {
  const navigate = useNavigate();

  const logout = async () => {
    tokenService.clearTokens();
    navigate("/");
    window.location.reload();
  };
  return (
    <TransparentSmallButton
      id="logout"
      sx={{
        width: defaultGap,
        borderLeft: borderStyle,
        borderBottom: borderStyle,
      }}
      onClick={() => {
        logout();
      }}
    >
      <Icon name="Logout" />
    </TransparentSmallButton>
  );
}
