import { Stack } from "@mui/material";

import Choice from "../../../../../../common/components/fundamentals/Choice";
import { Task } from "../../../../../../common/models/Models";
import { MediumText } from "../../../../../../common/styles/Text.styles";
import { useTask } from "../../../context/TaskProvider";
import ConfigBox from "../ConfigBox";

export default function MetaTextSettings() {
  const { task } = useTask();

  const metadataOptions =
    task.configs.marketingMetadata.nodes.MetadataNode.metadata.options;

  return (
    <ConfigBox>
      <Stack>
        <MediumText>Meta text settings</MediumText>
      </Stack>
      <Stack>
        {metadataOptions.map((o, idx) => (
          <Choice
            choice={o}
            key={idx}
            isLast={idx === metadataOptions.length - 1}
            checkIsCurrentlySelected={(choice, t: Task) =>
              t.configs.marketingMetadata.nodes.MetadataNode.metadata.value.includes(
                choice
              )
            }
            valueParamPath="configs.marketingMetadata.nodes.MetadataNode.metadata.value"
            valueList={
              task.configs.marketingMetadata.nodes.MetadataNode.metadata.value
            }
          />
        ))}
      </Stack>
    </ConfigBox>
  );
}
