import { Stack } from "@mui/material";
import { useState } from "react";
import Dropdown from "../../common/components/fundamentals/Dropdown";
import { BiggerIcon } from "../../common/components/fundamentals/Icon";
import SearchBar from "../../common/components/layout/SearchBar";
import NotImplemented from "../../common/components/utils/NotImplemented";
import { FullStack, OrangeOnHoverStack } from "../../common/styles/Box.styles";
import { CustomFilterBox } from "../../common/styles/Form.styles";
import { HeadingText, SmallText } from "../../common/styles/Text.styles";
import { modulesDashboard } from "../../routes";
import {
  borderOrange,
  borderStyle,
  checkboxGap,
  defaultGap,
  defaultTransition,
  perelynBlack,
  perelynPrimary,
  smallerGap,
  smallTextFieldWidth,
} from "../../styles/consts";
import ModuleBox from "./components/ModuleBox";

export const filterOptions = [
  "Entity extraction",
  "Search",
  "Compliance",
  "Feedback",
  "Social media",
];

export default function ModulesDashboardPage() {
  const [filteredModules, setFilteredModules] = useState(modulesDashboard);
  const [currentFilter, setCurrentFilter] = useState("");

  const clearFilter = () => {
    setCurrentFilter("");
    setFilteredModules(modulesDashboard);
  };

  const filter = (filterTag) => {
    if (currentFilter === filterTag) {
      clearFilter();
    } else {
      setCurrentFilter(filterTag);
      const filtered = modulesDashboard.filter((module) => {
        if (filterTag === "") return true;
        return module.tags.includes(filterTag);
      });
      setFilteredModules(filtered);
    }
  };

  return (
    <FullStack
      sx={{
        flexDirection: "row",
      }}
    >
      <FullStack>
        <SearchBar type={"tools"} />
        <Stack
          sx={{
            padding: `${smallerGap} ${defaultGap} ${smallTextFieldWidth}`,
            boxSizing: "border-box",
            gap: defaultGap,
            borderBottom: borderStyle,
          }}
        >
          <Stack
            sx={{
              paddingBottom: smallerGap,
              flexDirection: "row",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                gap: checkboxGap,
                whiteSpace: "nowrap",
                alignItems: "center",
                boxSizing: "border-box",
                maxHeight: "2rem",
              }}
            >
              <HeadingText paddingRight={checkboxGap}>Filter</HeadingText>

              {filterOptions.map((option) => (
                <CustomFilterBox
                  sx={{
                    "&&": {
                      border:
                        currentFilter === option ? borderOrange : borderStyle,
                    },
                  }}
                  onClick={() => filter(option)}
                  key={option}
                >
                  <SmallText
                    sx={{
                      transition: defaultTransition,
                      color:
                        currentFilter === option
                          ? perelynPrimary
                          : perelynBlack,
                    }}
                  >
                    {option}
                  </SmallText>
                </CustomFilterBox>
              ))}

              <OrangeOnHoverStack sx={{ transition: defaultTransition }}>
                <BiggerIcon
                  onClick={clearFilter}
                  sx={{ height: "1.75rem" }}
                  name="XMark"
                />
              </OrangeOnHoverStack>
            </Stack>
            <NotImplemented onLeft>
              <Dropdown
                options={[
                  { value: "All" },
                  { value: "Product ready", iconName: "SquareCheck" },
                  { value: "PoC", iconName: "FrenchKey" },
                  { value: "Idea", iconName: "LightBulb" },
                  { value: "Newest" },
                  { value: "Oldest" },
                ]}
                initialSelected="All"
              />
            </NotImplemented>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              gap: defaultGap,
              flexWrap: "wrap",
            }}
          >
            {filteredModules.map((module, idx) => (
              <ModuleBox key={idx} module={module} />
            ))}
          </Stack>
        </Stack>
      </FullStack>
    </FullStack>
  );
}
